import { Link, useParams } from 'react-router-dom'
import ReactTable, { TableProps } from 'react-table'
import { TermSubTerm } from '@tovala/browser-apis-combinedapi'
import { useEffect } from 'react'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'
import { getTerm } from '../../actions/terms'
import { isTerm } from 'utils/terms'

import { useAppDispatch, useAppSelector } from 'hooks'
import H1 from 'components/common/H1'

const TermMenus = (): JSX.Element => {
  const { termID } = useParams<{ termID: string }>()

  const dispatch = useAppDispatch()

  const selectedTerm = useAppSelector((state) => state.terms.selectedTerm)

  useEffect(() => {
    document.title = `Glaze | Term #${termID} Menus`
  }, [termID])

  useEffect(() => {
    if (termID) {
      dispatch(getTerm(termID))
    }
  }, [dispatch, termID])

  const columns: TableProps<TermSubTerm>['columns'] = [
    {
      Header: 'Name',
      accessor: 'defaultMenu.name',
      width: 300,
    },
    {
      Header: 'Facility Network',
      accessor: 'facilityNetwork',
      width: 300,
    },
    {
      Header: 'Cycle',
      accessor: 'shipPeriod',
      width: 100,
    },
    {
      accessor: 'defaultMenu.id',
      width: 70,
      show: getAdminScope(MEALS_WRITE),
      Cell: (props: { value: string }) => (
        <Link
          className="ml-2 text-sm font-semibold uppercase tracking-widest text-red-901"
          to={`/terms/${termID}/menus/edit/${props.value}`}
        >
          Edit
        </Link>
      ),
    },
  ]

  return (
    <div>
      {isTerm(selectedTerm) && (
        <div>
          <H1>{selectedTerm && <span>Term #{selectedTerm.id}</span>} Menus</H1>

          {selectedTerm.subTerms && (
            <ReactTable
              className="-striped -highlight"
              columns={columns}
              data={selectedTerm.subTerms}
              defaultSorted={[
                {
                  id: 'created',
                  desc: true,
                },
              ]}
              filterable
              pageSize={4}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TermMenus
