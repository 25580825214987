import { useEffect, useRef, useState } from 'react'
import { FieldArray, Form, Formik, FormikProps, useField } from 'formik'
import { TermMenu } from '@tovala/browser-apis-combinedapi'
import { useParams } from 'react-router-dom'
import ReactSelect from 'react-select'

import {
  addSideSwapsForMenu,
  deleteSideSwapsForMenu,
  getTerm,
} from '../../actions/terms'
import { isTerm } from 'utils/terms'
import { ReactSelectValue } from 'types/internal'

import { useAppDispatch, useAppSelector } from 'hooks'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import ConfirmationModal from '../modals/ConfirmationModal'
import H1 from 'components/common/H1'
import Hr from 'components/common/Hr'
import XIcon from 'components/common/icons/XIcon'

interface FormData {
  menus: TermMenu[]
  selectedMenu?: TermMenu
}

const Select = (props: {
  name: string
  onChange(newOption: ReactSelectValue<number> | null): void
  options: ReactSelectValue<number>[]
  validate(value: ReactSelectValue<number> | null): string | undefined
  value: ReactSelectValue<number> | null
}): JSX.Element => {
  const [field] = useField({ name: props.name, validate: props.validate })

  return <ReactSelect {...field} {...props} />
}

const TermSideswap = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { termid } = useParams<{ termid: string }>()

  const selectedTerm = useAppSelector((state) => state.terms.selectedTerm)

  const formRef = useRef<FormikProps<FormData> | null>(null)

  const [selectedMenu, setSelectedMenu] = useState<TermMenu | ''>('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleSubmit = (values: FormData) => {
    const menuID = values.selectedMenu?.id

    if (termid && menuID) {
      const selectedMenu = values.menus.find((menu) => menu.id === menuID)

      if (selectedMenu) {
        const mealSwaps = {}
        selectedMenu.mealSwaps.forEach((mealSwap) => {
          mealSwaps[mealSwap.mealID] = mealSwap.swapIDs
        })

        dispatch(addSideSwapsForMenu(menuID, mealSwaps)).then((response) => {
          if (response) {
            dispatch(getTerm(termid))
          }
        })
      }
    }
  }

  function requiredField(value: ReactSelectValue<number> | null | -1) {
    // We use -1 to indicate a mealID that has not yet been chosen.
    return !value || value === -1 ? 'Required' : ''
  }

  const toggleDeleteModal = (menu?: TermMenu) => {
    setShowDeleteModal((showModal) => !showModal)
    setSelectedMenu(menu ?? '')
  }

  const handleDelete = () => {
    if (termid && selectedMenu) {
      dispatch(deleteSideSwapsForMenu(selectedMenu.id)).then((response) => {
        if (response) {
          dispatch(getTerm(termid))
        }
      })
      toggleDeleteModal()
    }
  }

  useEffect(() => {
    document.title = `Glaze | Customize It`
  }, [])

  useEffect(() => {
    if (termid) {
      dispatch(getTerm(termid))
    }
  }, [dispatch, termid])

  let menus: TermMenu[] | '' = ''
  if (isTerm(selectedTerm) && selectedTerm.subTerms) {
    menus = selectedTerm.subTerms.map((subTerm) => subTerm.menus).flat()
  }

  return (
    <div>
      {menus && (
        <Formik<FormData>
          enableReinitialize
          initialValues={{ menus }}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <H1>
                  Term #{isTerm(selectedTerm) ? selectedTerm.id : ''} Customize
                  It
                </H1>

                <div className="flex space-x-4">
                  {menus &&
                    menus.map((menu, menuIndex) => {
                      const canAddPairs =
                        menus &&
                        menus[menuIndex].mealSwaps &&
                        menus[menuIndex].mealSwaps.length === 0
                      const mealOptions = menu.meals
                        .map((meal) => {
                          return {
                            value: meal.id,
                            label: `${meal.id} - ${meal.title} ${meal.shortSubtitle}`,
                          }
                        })
                        .sort((a, b) => a.value - b.value)

                      const mealSwaps = values.menus[menuIndex]?.mealSwaps

                      const menuErrors = errors.menus?.[menuIndex]
                      const menuSwapErrors =
                        typeof menuErrors === 'string'
                          ? null
                          : menuErrors?.mealSwaps

                      return (
                        <div
                          key={menu.id}
                          className="w-3/12 border-r border-grey-900 pr-4"
                        >
                          <h5 className="mb-4">Menu: {menu.name}</h5>
                          <FieldArray
                            name={`menus[${menuIndex}].mealSwaps`}
                            render={(arrayHelpers) => {
                              return (
                                <div>
                                  {mealSwaps && mealSwaps.length > 0 && (
                                    <div className="space-y-4">
                                      {mealSwaps.map(
                                        (_mealSwap, mealSwapsIndex) => {
                                          const mealSwapPath = `menus.${menuIndex}.mealSwaps.${mealSwapsIndex}`
                                          const primaryMeal = menu.meals.find(
                                            (meal) =>
                                              meal.id ===
                                              mealSwaps[mealSwapsIndex].mealID
                                          )

                                          const isProteinChoice =
                                            primaryMeal?.tags.find(
                                              (tag) => tag.id === 94
                                            )

                                          const primaryMealValue =
                                            mealOptions.find(
                                              (option) =>
                                                option.value ===
                                                mealSwaps[mealSwapsIndex].mealID
                                            ) ?? null

                                          const swapMealValues = mealSwaps[
                                            mealSwapsIndex
                                          ].swapIDs.map((swapID) => {
                                            return (
                                              mealOptions.find(
                                                (option) =>
                                                  option.value === swapID
                                              ) ?? null
                                            )
                                          })

                                          const hasMultipleSwapMeals =
                                            swapMealValues.length > 1

                                          if (!menus) {
                                            return
                                          }

                                          const errorsForIndex =
                                            menuSwapErrors &&
                                            menuSwapErrors[mealSwapsIndex]
                                              ? menuSwapErrors[mealSwapsIndex]
                                              : null

                                          return (
                                            <div
                                              key={mealSwapsIndex}
                                              className="space-y-4"
                                            >
                                              <div className="flex justify-between">
                                                <strong>
                                                  {isProteinChoice
                                                    ? 'Protein Choice'
                                                    : 'Customize It Pair'}
                                                </strong>
                                                {canAddPairs && (
                                                  <button
                                                    className="text-sm uppercase tracking-widest hover:underline"
                                                    onClick={() =>
                                                      arrayHelpers.remove(
                                                        mealSwapsIndex
                                                      )
                                                    }
                                                    type="button"
                                                  >
                                                    Remove
                                                  </button>
                                                )}
                                              </div>

                                              {primaryMeal &&
                                                hasMultipleSwapMeals &&
                                                !isProteinChoice && (
                                                  <AlertInline alertStyle="warning">
                                                    Please add the &quot;Protein
                                                    Choice&quot; tag to meal{' '}
                                                    {primaryMeal.id} -{' '}
                                                    {primaryMeal.title} to
                                                    enable the protein choice
                                                    display mode for meals with
                                                    more than one secondary
                                                    meal.
                                                  </AlertInline>
                                                )}

                                              <div>
                                                <label>Primary Meal</label>
                                                {canAddPairs ? (
                                                  <>
                                                    {errorsForIndex &&
                                                      typeof errorsForIndex !==
                                                        'string' &&
                                                      errorsForIndex.mealID && (
                                                        <span className="text-red-901">
                                                          &nbsp;
                                                          {
                                                            errorsForIndex.mealID
                                                          }
                                                        </span>
                                                      )}

                                                    <Select
                                                      name={`${mealSwapPath}.mealID`}
                                                      onChange={(option) =>
                                                        setFieldValue(
                                                          `${mealSwapPath}.mealID`,
                                                          option?.value
                                                        )
                                                      }
                                                      options={mealOptions}
                                                      validate={requiredField}
                                                      value={primaryMealValue}
                                                    />
                                                  </>
                                                ) : (
                                                  <p>
                                                    {primaryMealValue?.label}
                                                  </p>
                                                )}
                                              </div>

                                              <div>
                                                {canAddPairs ? (
                                                  <>
                                                    <FieldArray
                                                      name={`${mealSwapPath}.swapIDs`}
                                                      render={(
                                                        arrayHelpersSwaps
                                                      ) => {
                                                        return (
                                                          <>
                                                            <div className="flex items-center justify-between pb-2">
                                                              <label>
                                                                Secondary Meal
                                                                {hasMultipleSwapMeals
                                                                  ? 's'
                                                                  : ''}
                                                              </label>
                                                              <Button
                                                                buttonStyle="stroke"
                                                                onClick={() => {
                                                                  arrayHelpersSwaps.push(
                                                                    -1
                                                                  )
                                                                }}
                                                                size="large"
                                                              >
                                                                + Add Meal
                                                              </Button>
                                                            </div>

                                                            <div className="space-y-2">
                                                              {swapMealValues.map(
                                                                (
                                                                  swapMeal,
                                                                  swapIndex
                                                                ) => {
                                                                  return (
                                                                    <div
                                                                      key={`swap${swapIndex}`}
                                                                    >
                                                                      {errorsForIndex &&
                                                                        typeof errorsForIndex !==
                                                                          'string' &&
                                                                        errorsForIndex
                                                                          .swapIDs?.[
                                                                          swapIndex
                                                                        ] && (
                                                                          <span className="text-red-901">
                                                                            &nbsp;
                                                                            {
                                                                              errorsForIndex
                                                                                .swapIDs[0]
                                                                            }
                                                                          </span>
                                                                        )}

                                                                      <div className="flex items-center">
                                                                        <div className="flex-1">
                                                                          <Select
                                                                            name={`${mealSwapPath}.swapIDs[${swapIndex}]`}
                                                                            onChange={(
                                                                              option
                                                                            ) =>
                                                                              setFieldValue(
                                                                                `${mealSwapPath}.swapIDs[${swapIndex}]`,
                                                                                option?.value
                                                                              )
                                                                            }
                                                                            options={
                                                                              mealOptions
                                                                            }
                                                                            validate={
                                                                              requiredField
                                                                            }
                                                                            value={
                                                                              swapMeal
                                                                            }
                                                                          />
                                                                        </div>
                                                                        {swapMealValues.length >
                                                                          1 && (
                                                                          <button
                                                                            className="ml-2 h-6 w-6"
                                                                            onClick={() =>
                                                                              arrayHelpersSwaps.remove(
                                                                                swapIndex
                                                                              )
                                                                            }
                                                                            type="button"
                                                                          >
                                                                            <XIcon />
                                                                          </button>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                }
                                                              )}
                                                            </div>
                                                          </>
                                                        )
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <p>
                                                      Secondary Meal
                                                      {hasMultipleSwapMeals
                                                        ? 's'
                                                        : ''}
                                                    </p>
                                                    {swapMealValues.map(
                                                      (swapMeal, index) => {
                                                        return (
                                                          <p
                                                            key={`${index}${swapMeal?.value}`}
                                                          >
                                                            {swapMeal?.label}
                                                          </p>
                                                        )
                                                      }
                                                    )}
                                                  </>
                                                )}
                                              </div>

                                              <Hr />
                                            </div>
                                          )
                                        }
                                      )}
                                    </div>
                                  )}

                                  {/* We can't update mealSwaps. They must be deleted and then re-created if necessary. */}
                                  {!canAddPairs && (
                                    <p>
                                      To edit groupings, please delete all and
                                      then re-add.
                                    </p>
                                  )}

                                  <div className="mt-4 flex justify-between">
                                    {canAddPairs && (
                                      <Button
                                        buttonStyle="stroke"
                                        onClick={() => {
                                          const newPair: TermMenu['mealSwaps'][number] =
                                            {
                                              mealID: -1,
                                              swapIDs: [-1],
                                            }

                                          arrayHelpers.push(newPair)
                                        }}
                                        size="large"
                                      >
                                        Add
                                      </Button>
                                    )}

                                    {mealSwaps && mealSwaps.length > 0 && (
                                      <div>
                                        {canAddPairs ? (
                                          <Button
                                            onClick={() =>
                                              setFieldValue(
                                                'selectedMenu',
                                                menu
                                              )
                                            }
                                            size="large"
                                            type="submit"
                                          >
                                            Save
                                          </Button>
                                        ) : (
                                          <Button
                                            buttonStyle="grey"
                                            onClick={() =>
                                              toggleDeleteModal(menu)
                                            }
                                            size="large"
                                          >
                                            Delete All
                                          </Button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          />
                        </div>
                      )
                    })}
                </div>
              </Form>
            )
          }}
        </Formik>
      )}

      <ConfirmationModal
        buttonText="Delete Pairs"
        handleClick={handleDelete}
        heading="Delete Pairs"
        isOpen={showDeleteModal}
        onCloseModal={() => {
          toggleDeleteModal()
        }}
      >
        <div>
          <strong>
            Are you sure you want to delete all groupings for Menu{' '}
            {selectedMenu && <span>{selectedMenu.name}</span>}?
          </strong>
        </div>
      </ConfirmationModal>
    </div>
  )
}

export default TermSideswap
