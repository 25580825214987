import { clsx } from 'clsx'
import { Combobox } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom'
import LoadingBarContainer from 'react-redux-loading-bar'
import { ReactNode, useState } from 'react'
import {
  UserV1SearchResult,
  useSearchUsersV1,
} from '@tovala/browser-apis-combinedapi'

import {
  getAdminScope,
  MEALS_READ,
  PRODUCTS_WRITE,
} from '../../utils/getAdminScope'

import { useAuth } from 'contexts/auth'
import { useThrottle } from 'hooks/general'
import ChevronDownIcon from './icons/ChevronDownIcon'
import Input from './Input'
import SearchIcon from './icons/SearchIcon'

const HEADER_HEIGHT = 'h-[56px]'
// The max height here is 4px more than the header height so the menu doesn't
// go right up to the edge of the screen.
const SEARCH_MENU_MAX_HEIGHT = 'max-h-[calc(100vh-60px)]'

const Header = (): JSX.Element => {
  const { isLoggedIn } = useAuth()

  return (
    <header>
      <LoadingBarContainer
        style={{
          position: 'fixed',
          backgroundColor: '#4a4a4a',
          height: '3px',
          zIndex: '9999',
          top: '0px',
        }}
      />

      <nav
        className={`fixed inset-0 z-20 flex ${HEADER_HEIGHT} items-center justify-between bg-grey-904 px-4`}
      >
        <div className="flex items-center space-x-8">
          <div className="flex h-full items-center">
            <Link to="/">
              <img
                alt="Tovala"
                className="w-[100px]"
                src="https://cdn.tovala.com/tovala.com/logo-title-dark.svg"
              />
            </Link>
          </div>

          {isLoggedIn && <UsersSearch />}
        </div>

        <ul className="flex h-full">
          {isLoggedIn ? (
            <>
              <li className="h-full">
                <Link
                  className="flex h-full items-center p-2 hover:text-grey-909"
                  to="/users"
                >
                  Users
                </Link>
              </li>
              <li className="h-full">
                {getAdminScope(MEALS_READ) && (
                  <Link
                    className="flex h-full items-center p-2 hover:text-grey-909"
                    to="/meals"
                  >
                    Meals
                  </Link>
                )}
              </li>
              <li className="group relative h-full">
                <a
                  className="flex h-full items-center space-x-2 p-2 hover:text-grey-909"
                  href="#"
                  role="button"
                >
                  <span>Marketing</span>
                  <div className="h-4 w-4">
                    <ChevronDownIcon />
                  </div>
                </a>
                <div className="absolute left-0 top-full hidden w-48 bg-grey-904 group-hover:block">
                  <Link
                    className="block p-2 hover:text-grey-909"
                    to="/coupon-codes"
                  >
                    Coupon Codes
                  </Link>
                  <Link
                    className="block p-2 hover:text-grey-909"
                    to="/mdd-levers"
                  >
                    MDD Levers
                  </Link>
                  {getAdminScope(PRODUCTS_WRITE) && (
                    <Link
                      className="block p-2 hover:text-grey-909"
                      to="/leverator"
                    >
                      Leverator
                    </Link>
                  )}
                  <Link
                    className="block p-2 hover:text-grey-909"
                    to="/marketing/meals-data"
                  >
                    Upcoming Meals Email Data
                  </Link>
                </div>
              </li>
              <li>
                <Link
                  className="flex h-full items-center p-2 hover:text-grey-909"
                  to="/logout"
                >
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <li className="h-full">
              <Link
                className="flex h-full items-center p-2 hover:text-grey-909"
                to="/login"
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default Header

const UsersSearch = () => {
  const navigate = useNavigate()
  const [selectedResult, setSelectedResult] = useState<string | null>(null)

  const {
    setValue: setSearchTerm,
    throttledValue: throttledSearchTerm,
    value: searchTerm,
  } = useThrottle('', 300)

  const trimmedSearchTerm = throttledSearchTerm
    ? throttledSearchTerm.trim()
    : ''

  return (
    <Combobox
      onChange={(userID) => {
        setSelectedResult(userID)

        if (userID) {
          navigate(`/user/${userID}/account-overview`)
        }
      }}
      value={selectedResult}
    >
      <div className="relative">
        <Combobox.Button as="div" className="w-80">
          <Combobox.Input
            as={Input}
            autoComplete="off"
            displayValue={() => searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
            placeholder="Search users..."
            rightIcon={
              <div className="h-4 w-4 text-gray-400">
                <SearchIcon />
              </div>
            }
          />
        </Combobox.Button>
        <Combobox.Options
          className={clsx(
            'absolute z-10 mt-1 w-96 divide-y divide-grey-903 overflow-auto rounded border border-grey-900 bg-white-900 py-4 shadow-md',
            // The max-height here is so that the menu never extends past the boundary of the screen.
            SEARCH_MENU_MAX_HEIGHT
          )}
        >
          <div className="pb-4">
            <IDResults searchTerm={trimmedSearchTerm} />
          </div>
          <div className="py-4">
            <AddressPhoneResults searchTerm={trimmedSearchTerm} />
          </div>
          <div className="pt-4">
            <NameEmailResults searchTerm={trimmedSearchTerm} />
          </div>
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

const SearchNeedsMoreChars = ({
  numCharsNeeded,
}: {
  numCharsNeeded: number
}) => {
  return (
    <p className="px-4 text-sm text-grey-905">
      Please enter at least {numCharsNeeded} letter
      {numCharsNeeded > 1 ? 's' : ''} to start searching.
    </p>
  )
}

const SearchResultsSection = ({
  children,
  isLoading,
  numResults,
  prompt = undefined,
  title,
}: {
  children: ReactNode
  isLoading: boolean
  numResults: number
  prompt?: ReactNode
  title: string
}) => {
  return (
    <div className="space-y-2">
      <div className="px-4 text-xs font-bold uppercase text-grey-905">
        {title}
      </div>

      <div>
        {prompt ? (
          prompt
        ) : !isLoading && numResults === 0 ? (
          <p className="px-4 text-sm text-grey-905">No results</p>
        ) : (
          children
        )}
      </div>
    </div>
  )
}

const SearchResult = ({ result }: { result: UserV1SearchResult }) => {
  return (
    <Combobox.Option
      className="px-4 py-2 hover:cursor-pointer ui-selected:font-bold ui-active:bg-grey-902"
      value={result.id}
    >
      <span className="block">{result.name}</span>
      <span className="text-sm text-grey-906">{result.email}</span>
    </Combobox.Option>
  )
}

const IDResults = ({ searchTerm }: { searchTerm: string }) => {
  const hasLongEnoughSearchTerm = searchTerm.length > 0

  const { data: results = [], isFetching: isLoading } = useSearchUsersV1({
    enabled: hasLongEnoughSearchTerm,
    retry: false,
    // Non-number IDs don't make sense so we won't try to make a request if
    // we can't parse the search term as a number.
    searchTerm: !Number.isNaN(Number(searchTerm)) ? searchTerm : '',
    searchParam: 'id',
  })

  return (
    <SearchResultsSection
      isLoading={isLoading}
      numResults={results.length}
      prompt={
        hasLongEnoughSearchTerm ? undefined : (
          <SearchNeedsMoreChars numCharsNeeded={1} />
        )
      }
      title="ID"
    >
      {/*
       * This arbitrarily shows a few results and expects the user will type more if they
       * don't see what they want.
       */}
      {results.slice(0, 4).map((result) => {
        return <SearchResult key={result.id} result={result} />
      })}
    </SearchResultsSection>
  )
}

const AddressPhoneResults = ({ searchTerm }: { searchTerm: string }) => {
  const hasLongEnoughSearchTerm = searchTerm.length > 2

  const { data: results = [], isFetching: isLoading } = useSearchUsersV1({
    enabled: hasLongEnoughSearchTerm,
    retry: false,
    searchTerm: hasLongEnoughSearchTerm ? searchTerm : '',
    searchParam: 'address',
  })

  return (
    <SearchResultsSection
      isLoading={isLoading}
      numResults={results.length}
      prompt={
        hasLongEnoughSearchTerm ? undefined : (
          <SearchNeedsMoreChars numCharsNeeded={3} />
        )
      }
      title="Address/Phone"
    >
      {/*
       * This arbitrarily shows a few results and expects the user will type more if they
       * don't see what they want.
       */}
      {results.slice(0, 4).map((result) => {
        return <SearchResult key={result.id} result={result} />
      })}
    </SearchResultsSection>
  )
}

const NameEmailResults = ({ searchTerm }: { searchTerm: string }) => {
  const hasLongEnoughSearchTerm = searchTerm.length > 2

  const { data: results = [], isFetching: isLoading } = useSearchUsersV1({
    enabled: hasLongEnoughSearchTerm,
    retry: false,
    searchTerm: hasLongEnoughSearchTerm ? searchTerm : '',
    searchParam: 'nameOrEmail',
  })

  return (
    <SearchResultsSection
      isLoading={isLoading}
      numResults={results.length}
      prompt={
        hasLongEnoughSearchTerm ? undefined : (
          <SearchNeedsMoreChars numCharsNeeded={3} />
        )
      }
      title="Name/Email"
    >
      {/*
       * This arbitrarily shows a few results and expects the user will type more if they
       * don't see what they want.
       */}
      {results.slice(0, 4).map((result) => {
        return <SearchResult key={result.id} result={result} />
      })}
    </SearchResultsSection>
  )
}
