import {
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import {
  createPublicTermMenus,
  CreatePublicTermMenusResponse,
  GetMeal,
  getMeal,
  GetMealResponse,
  GetTermRequest,
  GetTermResponse,
  getTerm,
} from 'services/combinedAPI/menus'
import {
  getMealSummaries,
  GetMealSummariesRequest,
  GetMealSummariesResponse,
} from '@tovala/browser-apis-combinedapi'
import {
  getMenuComponents,
  GetMenuComponentsRequest,
  GetMenuComponentsResponse,
} from '@tovala/browser-apis-cdn'
import { minutesToMs } from '@tovala/browser-apis-core'

export function useGetMeal({
  mealID,
  ...rest
}: Partial<GetMeal> &
  Omit<
    UseQueryOptions<GetMealResponse, Error>,
    'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
  >) {
  return useQuery<GetMealResponse, Error>({
    ...rest,
    enabled: !!mealID,
    queryFn: () => {
      if (!mealID) {
        throw new Error('Cannot get meal because a meal ID was not provided')
      }

      return getMeal({ mealID })
    },
    queryKey: ['admin-meal', mealID],
    staleTime: minutesToMs(1),
  })
}

export function useGetTerm({
  termID,
  ...rest
}: Partial<GetTermRequest> &
  Omit<
    UseQueryOptions<GetTermResponse, Error>,
    'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
  >) {
  return useQuery<GetTermResponse, Error>({
    ...rest,
    enabled: !!termID,
    queryFn: () => {
      if (!termID) {
        throw new Error('Cannot get term because a termID was not provided')
      }

      return getTerm({ termID })
    },
    queryKey: ['term', termID],
    staleTime: minutesToMs(10),
  })
}

export function useCreatePublicTermMenus(
  opts?: Omit<
    UseMutationOptions<CreatePublicTermMenusResponse, Error>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: () => {
      return createPublicTermMenus()
    },
    onSuccess: (...args) => {
      opts?.onSuccess?.(...args)
    },
  })
}

function getMenuComponentsQueryConfig({
  enabled = true,
  subTermID,
  ...rest
}: Partial<GetMenuComponentsRequest> &
  Omit<
    UseQueryOptions<GetMenuComponentsResponse, Error>,
    'queryFn' | 'queryKey' | 'staleTime'
  >) {
  return {
    ...rest,
    enabled: !!subTermID && enabled,
    retry: false,
    queryFn: () => {
      if (!subTermID) {
        throw new Error('No subTermID provided to fetch menu components.')
      }

      return getMenuComponents({ subTermID })
    },
    queryKey: ['menu-components', subTermID],
    staleTime: minutesToMs(10),
  }
}

export function useTermMenuComponents({
  subTermIDs = [],
  ...rest
}: {
  subTermIDs: string[]
} & Omit<
  UseQueryOptions<GetMenuComponentsResponse, Error>,
  'queryFn' | 'queryKey'
>) {
  return useQueries({
    ...rest,
    queries: subTermIDs.map<UseQueryOptions<GetMenuComponentsResponse, Error>>(
      (subTermID) => {
        return getMenuComponentsQueryConfig({ subTermID, ...rest })
      }
    ),
  })
}

function getMealSummariesQueryConfig({
  subTermID,
  ...rest
}: Partial<GetMealSummariesRequest> &
  Omit<
    UseQueryOptions<GetMealSummariesResponse, Error>,
    'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
  >) {
  return {
    ...rest,
    enabled: !!subTermID,
    queryFn: () => {
      if (!subTermID) {
        throw new Error(
          'Cannot get meal summaries because no subTerm ID was provided'
        )
      }

      return getMealSummaries({ subTermID })
    },
    queryKey: ['meal-summaries', subTermID],
    staleTime: minutesToMs(10),
  }
}

export function useTermMealSummaries({
  subTermIDs = [],
  ...rest
}: {
  subTermIDs: string[]
} & Omit<
  UseQueryOptions<GetMealSummariesResponse, Error>,
  'queryFn' | 'queryKey'
>) {
  return useQueries({
    ...rest,
    queries: subTermIDs.map<UseQueryOptions<GetMealSummariesResponse, Error>>(
      (subTermID) => {
        return getMealSummariesQueryConfig({ subTermID, ...rest })
      }
    ),
  })
}
