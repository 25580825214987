import { MealSelectionPricing } from '@tovala/browser-apis-combinedapi'
import { formatCentsToDollars } from 'utils/currency'

export interface OrderPricing {
  mealsPriceCents: number
  shippingPriceCents: number
  surchargePriceCents: number
  totalPriceCents: number
}

export function getMealPriceString({ surchargeCents, totalPriceCents }) {
  const surchargeString =
    surchargeCents > 0
      ? `+${formatCentsToDollars(surchargeCents)} surcharge | `
      : ''
  const totalString = `${formatCentsToDollars(totalPriceCents)}`

  return surchargeString + totalString
}

export function getOrderPricing({
  listingSelectionsPricing,
  subscriptionPricing,
}: {
  listingSelectionsPricing: number
  subscriptionPricing: MealSelectionPricing | null
}): OrderPricing {
  return {
    mealsPriceCents: subscriptionPricing?.mealsBasePriceAmountCents ?? 0,
    shippingPriceCents: subscriptionPricing?.shippingAmountCents ?? 0,
    surchargePriceCents: subscriptionPricing
      ? subscriptionPricing.mealsSurchargeAmountCents + listingSelectionsPricing
      : 0,
    totalPriceCents: subscriptionPricing
      ? subscriptionPricing.totalAmountCents + listingSelectionsPricing
      : 0,
  }
}
