import { useState, useEffect } from 'react'

import { getNextTerm } from '../../actions/terms'
import { isTerm } from 'utils/terms'
import { uploadOvenShippingDelayCSV } from '../../actions/marketing'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import FileDropzone from '../common/FileDropzone'
import H1 from 'components/common/H1'
import H4 from 'components/common/H4'

const OvenShippingDelay = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const term = useAppSelector((state) => state.terms.term)

  const [file, setFile] = useState<File | ''>('')

  useEffect(() => {
    document.title = `Glaze | Oven Shipping Delay`
  }, [])

  useEffect(() => {
    dispatch(getNextTerm())
  }, [dispatch])

  const handleDrop = (files: File[]) => {
    setFile(files[0])
  }

  const handleSubmit = () => {
    if (!isTerm(term)) {
      return
    }

    const formData = new FormData()

    formData.append('file', file)

    dispatch(uploadOvenShippingDelayCSV(term.id, formData))

    setFile('')
  }

  return (
    <div>
      <H1>Oven Shipping Delay CSV Upload</H1>
      {isTerm(term) && term.id && (
        <div>
          <H4>Term #{term.id}</H4>

          {file ? (
            <p>{file.name}</p>
          ) : (
            <FileDropzone
              accept={{ 'text/csv': ['.csv'] }}
              maxFiles={1}
              onDrop={handleDrop}
            />
          )}

          <div className="mt-4">
            <Button onClick={handleSubmit} size="large">
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default OvenShippingDelay
