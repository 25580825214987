import { Field, Form, Formik } from 'formik'
import { InputHTMLAttributes, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { getMenu, getTerm, updateMenuInfo } from '../../actions/terms'
import { isTerm, isTermMenu } from 'utils/terms'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import FormLabel from 'components/common/FormLabel'
import H1 from 'components/common/H1'
import Input from 'components/common/Input'

interface FormData {
  description: string
  isDefault: boolean
  name: string
  notes: string
  subtermID: string
}

const InputInternal = ({
  disabled,
  field,
  label,
  type = 'text',
  ...rest
}: {
  disabled: boolean
  field: InputHTMLAttributes<HTMLInputElement>
  label: string
} & InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  return (
    <div>
      <FormLabel>{label}</FormLabel>
      <Input
        disabled={disabled}
        type={type ? type : 'text'}
        {...field}
        {...rest}
      />
    </div>
  )
}

const EditMenu = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { menuID, termID } = useParams<{ menuID: string; termID: string }>()
  const readOnly = false

  const selectedMenu = useAppSelector((state) => state.terms.selectedMenu)
  const selectedTerm = useAppSelector((state) => state.terms.selectedTerm)

  const handleSubmit = (values: FormData) => {
    if (!menuID) {
      return
    }

    dispatch(updateMenuInfo(menuID, values))
  }

  useEffect(() => {
    document.title = `Glaze | Edit Menu`
  }, [])

  useEffect(() => {
    if (menuID) {
      dispatch(getMenu(menuID))
    }
  }, [dispatch, menuID])

  useEffect(() => {
    if (termID) {
      dispatch(getTerm(termID))
    }
  }, [dispatch, termID])

  return (
    <div>
      {isTerm(selectedTerm) && (
        <Link to={`/terms/${selectedTerm.id}/menus`}> ❮ Back to Menus</Link>
      )}
      {selectedMenu && (
        <H1>
          Edit Menu: {selectedMenu.name && <span>{selectedMenu.name}</span>}
        </H1>
      )}

      {selectedMenu && (
        <Formik<FormData>
          enableReinitialize
          initialValues={{
            description: selectedMenu.description,
            isDefault: true,
            name: selectedMenu.name,
            notes: isTermMenu(selectedMenu) ? '' : selectedMenu.notes,
            subtermID: selectedMenu.subtermID,
          }}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form className="space-y-4">
                <Field
                  component={InputInternal}
                  disabled={readOnly}
                  label="Name"
                  name="name"
                />
                <Field
                  component={InputInternal}
                  disabled={readOnly}
                  label="Description"
                  name="description"
                />
                <Field
                  component={InputInternal}
                  disabled={readOnly}
                  label="Is Default"
                  name="isDefault"
                />
                <Field
                  component={InputInternal}
                  disabled={readOnly}
                  label="Notes"
                  name="notes"
                />

                {!readOnly && (
                  <div className="mt-4 h-10 w-48">
                    <Button size="fluid" type="submit">
                      Save
                    </Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default EditMenu
