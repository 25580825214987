// ======================
// Auth Actions
// ======================
export const REQUEST_USER_CREATE = 'request_user_create' as const,
  GET_FULL_CUSTOMER_RECORD = 'get_full_customer_record' as const,
  UPDATE_SHIPPING_ADDRESS = 'update_shipping_address' as const,
  REMOVE_PAYMENT_SOURCE = 'remove_payment_source' as const,
  REFUND_ORDER_SUCCESS = 'refund_order_success' as const,
  GET_PRODUCT_TAX_ESTIMATE = 'get_product_tax_estimate' as const,
  GET_COUPON_AMOUNT = 'get_coupon_amount' as const,
  GET_PRODUCTS_PURCHASE_INFORMATION =
    'get_products_purchase_information' as const,
  GET_USER_INFO_V1 = 'get_user_info_v1' as const,
  SUCCESS_EXCHANGE_OVEN = 'success_exchange_oven' as const,
  SET_PLAN_PREFERENCES = 'set_plan_preferences' as const,
  CHANGE_SUBSCRIPTION_SUCCESS = 'change_subscription_success' as const,
  UPDATE_UTO = 'update_uto' as const,
  GET_REMAINING_ORDER_BALANCE = 'get_remaining_order_balance' as const,
  SHIPPING_CHARGE_REFUND = 'shipping_charge_refund' as const,
  ADDRESS_VALIDATION_SUCCESS = 'address_validation_success' as const

// ======================
// Meals
// ======================
export const GET_ALL_MEALS = 'get_all_meals' as const,
  GET_MEAL_INFO = 'get_meal_info' as const,
  ADD_MEAL = 'add_meal' as const,
  DELETE_MEAL = 'delete_meal' as const,
  UPDATE_MEAL = 'update_meal' as const,
  DUPLICATE_MEAL = 'duplicate_meal' as const,
  UPDATE_IMAGE_TEXT = 'update_image_text' as const,
  UPLOAD_IMAGE_FOR_MEAL = 'upload_image_for_meal' as const,
  ADD_MEAL_TO_SPECIFIC_TERM = 'add_meal_to_specific_term' as const,
  SUCCESS_MEAL_CREDIT_ADJUSTMENT = 'success_meal_credit_adjustment' as const,
  SUCCESS_ADDED_TERM = 'success_added_term' as const,
  SUCCESS_UPDATE_IMAGE_TEXT = 'success_update_update_text' as const,
  DELETE_IMAGE_FOR_MEAL = 'delete_image_for_meal' as const,
  ADD_MEAL_TAG_TO_MEAL = 'add_meal_tag_to_meal' as const,
  REMOVE_MEAL_TAG_FROM_MEAL = 'remove_meal_tag_from_meal' as const,
  SAVE_UNVALIDATED_ADDRESS = 'save_unvalidated_address' as const,
  SAVE_PHONE = 'save_phone' as const,
  TOGGLE_ADDRESS_VALIDATE_MODAL = 'toggle_address_validate_modal' as const,
  SAVE_VALIDATED_ADDRESS = 'save_validated_address' as const,
  SET_ADDRESS_VALIDATION_MESSAGE = 'set_address_validation_message' as const,
  VALIDATE_ADDRESS_BUTTON_TEXT = 'validate_address_button_text' as const,
  CREATE_BULK_MEALS = 'create_bulk_meals' as const,
  UPDATE_MENU_MEAL = 'update_menu_meal' as const,
  UPDATE_SOLD_OUT_COUNT = 'update_sold_out_count' as const

// ======================
// Meal Tags
// ======================
export const GET_ALL_MEAL_TAGS = 'get_all_meal_tags' as const,
  GET_MEAL_TAG_INFO = 'get_meal_tag_info' as const,
  ADD_MEAL_TAG = 'add_meal_tag' as const,
  DELETE_MEAL_TAG = 'delete_meal_tag' as const,
  UPDATE_MEAL_TAG = 'update_meal_tag' as const

// ======================
// QVC PACKSLIPS
// ======================
export const UPLOAD_PACKSLIPS = 'upload_packslips' as const
export const GET_THIRD_PARTY_VENDORS = 'get_third_party_vendors' as const

// ======================
// Search
// ======================
export const GET_DEFAULT_SEARCH = 'get_default_search' as const
export const GET_SEARCH_RESULTS = 'get_search_results' as const
export const SET_PAGE_NUMBER = 'set_page_number' as const
export const SET_SEARCH_TERM = 'set_search_term' as const
export const SET_SEARCH_PARAM = 'set_search_param' as const

// ======================
// Terms
// ======================
export const GET_ALL_TERMS = 'get_all_terms' as const,
  GET_NEXT_TERM = 'get_next_term' as const,
  GET_TERM = 'get_term' as const,
  GET_PUBLIC_CALENDAR = 'get_public_calendar' as const,
  UPDATE_SIDE_SWAP = 'update_side_swap' as const,
  GET_MENU = 'get_menu' as const,
  UPDATE_MENU_INFO = 'update_menu_info' as const

// ======================
// Page Actions
// ======================
export const STATIC_ERROR = 'static_error' as const

// ======================
// Marketing Actions
// ======================
export const ADD_MARKETING_ASSET = 'add_marketing_asset' as const
export const UPDATE_MARKETING_ASSET = 'update_marketing_asset' as const
export const GET_ALL_COUPON_CODES = 'get_all_coupon_codes' as const
export const ADD_COUPON_CODE = 'add_coupon_code' as const
export const ADD_COUPON_CODES = 'add_coupon_codes' as const
export const GET_COUPON_CODE = 'get_coupon_code' as const
export const GET_DEFAULT_CHECKOUT_SHIP_PERIOD =
  'get_default_checkout_ship_period' as const
export const GET_ALL_LEVERATOR_PAYLOADS = 'get_all_leverator_payloads' as const
export const GET_LEVERATOR_PAYLOAD = 'get_leverator_payload' as const
export const GET_ALL_LEVERATOR_ACTIONS = 'get_all_leverator_actions' as const
export const GET_LEVERATOR_ACTION = 'get_leverator_action' as const
export const GET_ALL_LEVERATOR_INAPP_OPTIONS =
  'get_all_leverator_inapp_options' as const
export const LEVERATOR_PUSH_SUCCESS = 'leverator_push_success' as const
export const SENDING_LEVERATOR_PUSH = 'sending_leverator_push' as const

// ======================
// Notification Actions
// ======================
export const SHOW_ERROR_NOTIFICATION = 'show_error_notification' as const
export const SHOW_SUCCESS_NOTIFICATION = 'show_success_notification' as const
export const SHOW_MESSAGE_NOTIFICATION = 'show_message_notification' as const
export const CLEAR_NOTIFICATIONS = 'clear_notifications' as const
