import { compact } from 'lodash-es'
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ListingAdmin,
  MealImage,
  MenuMeal,
  MenuProduct,
  RoutineStepClassic,
  RoutineStepIntent,
  Term,
  TermListItem,
  TermMeal,
} from '@tovala/browser-apis-combinedapi'
import { getMenuProductDetailsJSON } from '@tovala/browser-apis-cdn'
import moment from 'moment'

import {
  boxExtraTags,
  cookTimes,
  ingredientAllergens,
  nutrition,
} from './term-validator-data'
import { TermMealCombined } from 'types/internal'

import Button from 'components/common/Button'
import Hr from 'components/common/Hr'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'

interface MenuProductErrors {
  errors: string[]
  id: string
}

interface MealErrors {
  errors: string[]
  id: number
}

const premiumProteins = [
  {
    type: 'salmon',
    surchargeCents: 0, // Salmon will have a surcharge, but not a specific amount
  },
  {
    type: 'shrimp',
    surchargeCents: 299,
  },
  {
    type: 'trout',
    surchargeCents: 299,
  },
  {
    type: 'steak',
    surchargeCents: 499,
  },
]

const premiumExclusions = ['steak burrito', 'steak taco']

export function calorieSmartTaggedHasLessThan600Cal({
  meal,
  tag,
}: {
  meal: TermMealCombined
  tag: TermMealCombined['tags'][number]
}) {
  if (tag.title.toLowerCase() !== 'calorie smart') {
    return
  }

  if (meal.nutritionalInfo) {
    const calories = meal.nutritionalInfo.find(
      (info) => info.name.toLowerCase() === 'calories'
    )

    if (!calories) {
      return 'Has Calorie Smart tag, but is missing calories nutritional info.'
    }

    return Number(calories.value) < 600
      ? undefined
      : 'Has Calorie Smart tag and has more than 600 calories.'
  }
}

export function carbConsciousTaggedHas35gCarbsOrLess({
  meal,
  tag,
}: {
  meal: TermMealCombined
  tag: TermMealCombined['tags'][number]
}) {
  if (meal.nutritionalInfo) {
    const carbs = meal.nutritionalInfo.find(
      (info) => info.name.toLowerCase() === 'carbs'
    )

    if (
      tag.title.toLowerCase() === 'carb conscious' &&
      carbs &&
      Number(carbs.value) > 35
    ) {
      return 'Has Carb Conscious tag and has more than 35g carbs.'
    }
  }
}

export function checkForOptionalGarnishImage(
  meal: TermMealCombined,
  name: string
) {
  let hasImage = false
  const garnishName = name.toLowerCase().replace(/-|\s/g, '')

  if (meal.images) {
    hasImage = meal.images
      .filter((image) => image.caption.match(/Optional/i))
      .map((image) => {
        // Images for optional garnishes should include "(Optional)"
        const optionalTextStart = image.caption.indexOf('(')
        return image.caption
          .slice(0, optionalTextStart)
          .toLowerCase()
          .replace(/-|\s/g, '')
      })
      .includes(garnishName)
  }

  return hasImage
}

// Each garnish image labeled with "(optional)" should have a matching nutritional info item
export function checkForOptionalGarnishNutritionalInfo(
  meal: TermMealCombined,
  imageCaption: string
) {
  let hasNutrition = false
  // Images for optional garnishes should include "(Optional)"
  const optionalTextStart = imageCaption.indexOf('(')
  const caption = imageCaption
    .slice(0, optionalTextStart)
    .toLowerCase()
    .replace(/-|\s/g, '')
  let garnishNames: string[] | '' = ''
  if (meal.nutritionalInfo) {
    garnishNames = meal.nutritionalInfo
      .filter((item) => item.key === 'garnish')
      .map((garnish) => garnish.name.toLowerCase().replace(/-|\s/g, ''))
  }

  hasNutrition = garnishNames ? garnishNames.includes(caption) : false
  return hasNutrition
}

function checkIfImageLoads(url: string): Promise<boolean> {
  return new Promise((resolve) => {
    const image = new Image()
    image.onload = () => {
      resolve(true)
    }
    image.onerror = () => {
      resolve(false)
    }

    image.src = `https://${url}`
  })
}

export function cookTimeInSeconds(cookTime: string) {
  const time = cookTime.split(':')
  const seconds = +time[0] * 60 + +time[1]

  return seconds
}

export function dessertsHaveContainsDessertAddonTag(meal: TermMealCombined) {
  const message = 'Meal has cookie or cake and no "Contains Dessert Addon" tag.'

  const hasDessert = meal.subtitle.match(/\b(cake|cookie)\b/i)

  if (hasDessert) {
    const hasDessertAddonTag = meal.tags.some(
      (tag) => tag.title === 'Contains Dessert Addon'
    )

    return hasDessertAddonTag ? undefined : message
  }

  return undefined
}

export function dualBarcodesHaveDifferentCookTimes(
  routineMetadataObjects: TermMeal['routineMetadataObjects']
) {
  if (routineMetadataObjects && routineMetadataObjects.length === 2) {
    const cookTimes = routineMetadataObjects.map(
      (barcode) => barcode.routine && totalCookTime(barcode.routine.routine)
    )
    return cookTimes[0] !== cookTimes[1]
      ? undefined
      : 'Dual barcode cook times match'
  }
}

export function getSurchargeForProteinType(title: string) {
  if (
    !premiumExclusions.some((exclusion) =>
      title.toLowerCase().includes(exclusion)
    )
  ) {
    return premiumProteins.find((protein) =>
      title.toLowerCase().includes(protein.type)
    )?.surchargeCents
  }
  return 0
}

export function glutenFriendlyTaggedHasWheatAllergen({
  meal,
  tag,
}: {
  meal: TermMealCombined
  tag: TermMealCombined['tags'][number]
}) {
  return tag.title.toLowerCase() === 'gluten friendly'
    ? !hasAllergenNutritionalInfo(meal, 'wheat')
      ? undefined
      : 'Has Gluten Friendly tag and wheat as an allergen.'
    : undefined
}

export function hasAllergenNutritionalInfo(
  meal: TermMealCombined,
  allergen: string
) {
  let hasAllergen = false
  let allergens: string[] | '' = ''
  if (meal.nutritionalInfo) {
    allergens = meal.nutritionalInfo
      .filter((info) => info.key === 'allergen')
      .map((allergen) => allergen.name.toLowerCase())
    if (allergens.includes(allergen.toLowerCase())) {
      hasAllergen = true
    }
  }
  return hasAllergen
}

export function hasBOXEXTRATag(meal: TermMealCombined) {
  let message = ''
  let hasRequiredTag = true

  const requiredBOXEXTRATag = boxExtraTags.find((tag) => {
    const searchResults = tag.search.map((field) => {
      if (field === 'caption') {
        const imageCaptions =
          meal.images &&
          meal.images.map(
            (image) => image.caption && image.caption.toLowerCase()
          )
        return imageCaptions?.includes(tag.keyword)
      } else if (meal[field]) {
        return meal[field].toLowerCase().includes(tag.keyword)
      }
    })
    return searchResults.includes(true)
  })

  if (requiredBOXEXTRATag && meal.tags) {
    hasRequiredTag = meal.tags.some(
      (tag) =>
        tag.title === requiredBOXEXTRATag.tag ||
        (requiredBOXEXTRATag.variation &&
          tag.title === requiredBOXEXTRATag.variation.tag)
    )
  }

  if (!hasRequiredTag && requiredBOXEXTRATag) {
    message = `Missing ${requiredBOXEXTRATag.tag} tag.`
  }

  return !hasRequiredTag ? message : undefined
}

export function hasCellTileImage(images: MealImage[]) {
  return images.some((image) => image.key === 'cell_tile')
    ? undefined
    : 'Missing cell_tile image.'
}

export function hasExpectedNutritionValues(meal: TermMealCombined) {
  let message = ''
  let hasExpectedNutrition = true
  const expectedNutrition = nutrition.find((item) => {
    const searchResults = item.search.map((field) => {
      if (field === 'tags') {
        return meal.tags.some((tag) => tag.title === item.keyword)
      } else if (meal[field]) {
        return meal[field].toLowerCase().includes(item.keyword)
      }
    })
    return searchResults.includes(true)
  })

  if (expectedNutrition) {
    const expectedValues = compact(
      expectedNutrition.values.map((item) => {
        const nutritionalInfoItem = meal.nutritionalInfo.find(
          (n) => n.name.toLowerCase() === item.name
        )
        if (nutritionalInfoItem) {
          return Object.assign({}, item, {
            isValid: Number(nutritionalInfoItem.value) >= item.value,
            currentValue: nutritionalInfoItem.value,
          })
        }
      })
    )
    hasExpectedNutrition = !expectedValues
      .map((value) => value.isValid)
      .includes(false)
    if (!hasExpectedNutrition) {
      message = expectedValues
        .filter((value) => !value.isValid)
        .map(
          (value) =>
            `The value for ${value.name} (${value.currentValue}) is below the expected value of ${value.value}.`
        )
        .join(' ')
    }
  }

  return hasExpectedNutrition ? undefined : message
}

export function hasRequiredAllergenNoteBasedOnIngredients(
  meal: TermMealCombined
) {
  let message = ''
  let missingAllergenNote = false

  meal.ingredients.split(', ').forEach((ingredient) => {
    const allergens = ingredientAllergens.find((i) =>
      ingredient.includes(i.ingredient)
    )
    if (allergens && allergens.note) {
      if (!hasAllergenNutritionalInfo(meal, allergens.note)) {
        missingAllergenNote = true
        message =
          message +
          `Ingredients include ${ingredient}, but meal does not have the "${allergens.note}" allergen note. `
      }
    }
  })

  return missingAllergenNote ? message : undefined
}

export function hasRequiredAllergensBasedOnIngredients(meal: TermMealCombined) {
  let message = ''
  let missingAllergens = false

  // Search ingredients list for ingredients that have associated allergens
  const mealAllergens = ingredientAllergens.filter(({ ingredient }) => {
    const ingredientRegExp = new RegExp(`\\b${ingredient}\\b`, 'i')

    return meal.ingredients.match(ingredientRegExp)
  })

  if (mealAllergens.length) {
    mealAllergens.forEach(({ ingredient, allergens }) => {
      allergens.forEach((allergen) => {
        if (!hasAllergenNutritionalInfo(meal, allergen)) {
          missingAllergens = true
          message =
            message +
            `Ingredients include ${ingredient}, but meal does not have ${allergen} listed as an allergen. `
        }
      })
    })
  }

  return missingAllergens ? message : undefined
}

async function imageLoads(image: MealImage) {
  const message = `${image.filename} did not load.`

  const didImageLoad = await checkIfImageLoads(image.url)

  return didImageLoad ? undefined : message
}

// Expiration guidelines
// https://tovala.atlassian.net/wiki/spaces/MAR/pages/535789676/The+Meal+Copy+Stylebook+a.k.a.+how+to+make+meal+copy+accurate+look+pretty
export function isExpectedExpirationDate({
  menuMeal,
  term,
}: {
  menuMeal: MenuMeal
  term: Term | TermListItem
}) {
  if (!('subTerms' in term)) {
    return
  }

  const expirationDate = menuMeal.expirationDate
  const subTerm = term.subTerms.find(
    (subTerm) => subTerm.defaultMenu.id === menuMeal.menuID
  )
  if (!subTerm) {
    return
  }

  // Will address when moment is completely replaced with date-fns
  /* eslint-disable-next-line import/no-named-as-default-member */
  const earliestExpiration = moment.utc(subTerm.shipDate).add(5, 'days') // Saturday after term start for cycle 1, Monday for cycle 2
  /* eslint-disable-next-line import/no-named-as-default-member */
  const latestExpiration = moment.utc(subTerm.shipDate).add(8, 'days') // Tuesday the week after term start for cycle 1, Thur for cycle 2
  const isValid =
    expirationDate !== '0001-01-01T00:00:00Z' &&
    /* eslint-disable-next-line import/no-named-as-default-member */
    moment
      .utc(expirationDate)
      .isBetween(earliestExpiration, latestExpiration, 'day', '[]')
  let message = `Expected expiration to be between ${earliestExpiration.format(
    'ddd, M/D'
    /* eslint-disable-next-line import/no-named-as-default-member */
  )} and ${latestExpiration.format('ddd, M/D')}, but is ${moment
    .utc(expirationDate)
    .format('ddd, M/D')} (${subTerm.facilityNetwork} - Cycle ${
    subTerm.shipPeriod
  }).`
  if (expirationDate === '0001-01-01T00:00:00Z') {
    message = 'Expiration date invalid.'
  }

  return isValid ? undefined : message
}

export function isExpectedSurcharge(meal: TermMealCombined) {
  if (!('surchargeCents' in meal)) {
    return
  }

  const expectedSurcharge = getSurchargeForProteinType(meal.title) ?? 0
  let hasExpectedSurcharge = meal.surchargeCents === expectedSurcharge
  let message = `Expected premium surcharge of $${(
    expectedSurcharge / 100
  ).toFixed(2)}, but surcharge is $${(meal.surchargeCents / 100).toFixed(2)}.`

  if (meal.title.toLowerCase().includes('salmon')) {
    hasExpectedSurcharge = meal.surchargeCents > expectedSurcharge
    message = `This salmon meal does not have a surcharge.`
  }

  return hasExpectedSurcharge ? undefined : message
}

export function mealWithAddOnHasBoxextraTag(meal: TermMealCombined) {
  const message = 'Meal has add-on, but no BOXEXTRA tag.'

  // A meal with an add-on's subtitle will start with "includes" instead of "with" like a regular meal
  const hasAddOn = meal.subtitle.match(/includes/i)

  if (hasAddOn) {
    const hasBoxextraTag = meal.tags.some((tag) =>
      tag.title.toLowerCase().includes('boxextra')
    )

    return hasBoxextraTag ? undefined : message
  }

  return undefined
}

export function mealWithAddonHasSurcharge(meal: TermMealCombined) {
  if (!('surchargeCents' in meal)) {
    return
  }

  const message = 'Meal has add-on, but no surcharge.'

  // A meal with an add-on's subtitle will start with "includes" instead of "with" like a regular meal
  const hasAddOn = meal.subtitle.match(/includes/i)

  if (hasAddOn) {
    return meal.surchargeCents > 0 ? undefined : message
  }

  return undefined
}

export function noSurchargeAndNotPremium(meal: TermMealCombined) {
  if (!('surchargeCents' in meal)) {
    return
  }

  if (meal.surchargeCents === 0) {
    const premiumTagID = 64
    const hasTag = meal.tags && meal.tags.some((tag) => tag.id === premiumTagID)
    return !hasTag ? undefined : 'Has no surcharge, but has Premium tag.'
  }
}

export function noWheatAllergenHasGlutenFriendlyTag(meal: TermMealCombined) {
  if (
    meal.nutritionalInfo &&
    !meal.nutritionalInfo.some((item) => item.name === 'Wheat')
  ) {
    return meal.tags && meal.tags.some((tag) => tag.title === 'Gluten Friendly')
      ? undefined
      : 'Has no wheat allergen, but is missing Gluten Friendly tag.'
  }
}

export function optionalGarnishImageHasNutritionalInfo({
  image,
  meal,
}: {
  image: MealImage
  meal: TermMealCombined
}) {
  const isOptionalGarnish = image.caption.match(/Optional/i)

  if (isOptionalGarnish) {
    return checkForOptionalGarnishNutritionalInfo(meal, image.caption)
      ? undefined
      : `Optional garnish image ${image.caption} is missing nutritional info or names do not match.`
  }
}

export function optionalGarnishNutritionItemHasAnImage({
  info,
  meal,
}: {
  info: TermMealCombined['nutritionalInfo'][number]
  meal: TermMealCombined
}) {
  // Exclude meal extras from this check, which are using nutritional items with garnish keys with the meal extra and a dash (for example "Cake- Calories")
  if (info.key === 'garnish' && !info.name.includes('- ')) {
    return checkForOptionalGarnishImage(meal, info.name)
      ? undefined
      : `${info.name} is missing matching optional garnish image or "(Optional)" may be missing from the image caption.`
  }
}

export function saturatedFatIsLessThanTotalFat({
  info,
  meal,
}: {
  info: TermMealCombined['nutritionalInfo'][number]
  meal: TermMealCombined
}) {
  if (info.name === 'Saturated Fat') {
    const saturatedFatValue = info.value
    const totalFat = meal.nutritionalInfo.find(
      (item) => item.name === 'Total Fat'
    )
    const totalFatValue = totalFat ? totalFat.value : ''

    let message = 'Saturated Fat is not less than Total Fat.'
    if (!totalFat) {
      message = 'Total Fat is missing.'
    }

    return totalFatValue &&
      parseFloat(saturatedFatValue) <= parseFloat(totalFatValue)
      ? undefined
      : message
  }
}

export function sheetTrayMealHasTag(meal: TermMealCombined) {
  if (
    meal.mealPrepSteps &&
    meal.mealPrepSteps.toLowerCase().includes('sheet tray')
  ) {
    return meal.tags &&
      meal.tags.some((tag) => tag.title === 'Black Sheet Tray')
      ? undefined
      : 'Sheet tray meal is missing "Black Sheet Tray" tag.'
  }
}

export function shouldBeDualBarcodeMeal(title: string) {
  let dualBarcode = false
  if (
    title.toLowerCase().includes('steak') &&
    !premiumExclusions.some((exclusion) =>
      title.toLowerCase().includes(exclusion)
    )
  ) {
    dualBarcode = true
  }
  return dualBarcode
}

export function stepsAreNumberedCorrectly(mealPrepSteps: string) {
  let areNumberedCorrectly = true
  const message = 'Meal Prep Steps are not numbered correctly.'

  // mealPrepSteps is a string formatted as 1. Step instructions. 2. Step instructions.
  // Get all the step numbers, remove period, convert to int
  const steps = mealPrepSteps.match(/[0-9]+\./g)

  // If a meal includes a meal extra, it will have it's own set of steps that are support to start at "1."
  const stepGroups = steps?.reduce(function (
    groups: string[][],
    currentStep: string,
    index: number
  ) {
    if (index === 0 || currentStep === '1.') {
      groups.push([currentStep])
    } else {
      groups[groups.length - 1].push(currentStep)
    }
    return groups
  },
  [])

  if (stepGroups) {
    stepGroups.forEach((steps) => {
      const stepNumbers = steps.map((step) =>
        Number.parseInt(step.replace('.', ''), 10)
      )

      if (
        stepNumbers[0] !== 1 ||
        !stepNumbers.every((num, index) => num === index + 1)
      ) {
        areNumberedCorrectly = false
      }
    })

    return areNumberedCorrectly ? undefined : message
  }

  return message
}

export function sugarIsLessThanCarbs({
  info,
  meal,
}: {
  info: TermMealCombined['nutritionalInfo'][number]
  meal: TermMealCombined
}) {
  if (info.name === 'Sugar') {
    const sugarValue = info.value
    const carbs = meal.nutritionalInfo.find((item) => item.name === 'Carbs')
    const carbsValue = carbs ? carbs.value : ''

    let message = 'Sugar is not less than Carbs.'
    if (!sugarValue) {
      message = 'Sugar is missing.'
    }

    return sugarValue && parseFloat(sugarValue) <= parseFloat(carbsValue)
      ? undefined
      : message
  }
}

export function titleContainsPremiumProtein(title: string) {
  const containsPremiumProtein =
    premiumProteins.some((protein) =>
      title.toLowerCase().includes(protein.type)
    ) &&
    !premiumExclusions.some((exclusion) =>
      title.toLowerCase().includes(exclusion)
    )

  return containsPremiumProtein
}

export function totalCookTime(
  routines: (RoutineStepClassic | RoutineStepIntent)[] | null
) {
  let totalTime = 0

  if (routines) {
    for (const routine of routines) {
      if ('cookTime' in routine) {
        totalTime = totalTime + routine.cookTime
      } else {
        totalTime = totalTime + routine.time
      }
    }
  }

  const minutes = Math.floor(totalTime / 60)
  const seconds = totalTime - minutes * 60

  const totalTimeInMinsAndSeconds =
    minutes +
    ':' +
    seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
  return totalTimeInMinsAndSeconds
}

export function twoServingMealHasSurcharge(meal: TermMealCombined) {
  if (!('surchargeCents' in meal)) {
    return
  }

  const twoServingExcludeAutofillTagID = 65
  const hasTag =
    meal.tags &&
    meal.tags.some((tag) => tag.id === twoServingExcludeAutofillTagID)

  if (hasTag) {
    const message =
      'Has "2 Servings (Exclude From Autofill)" tag and no surcharge.'
    const hasSurcharge = meal.surchargeCents > 0

    return hasSurcharge ? undefined : message
  }

  return undefined
}

export function validCookTimeForIngredient(meal: TermMealCombined) {
  let message = ''

  const validCookTime = cookTimes.find((item) => {
    const searchResults = item.search.map((field) => {
      if (field === 'tags') {
        return meal.tags.some((tag) => tag.title === item.keyword)
      } else if (field === 'routineMetadataObjects') {
        if ('routineMetadataObjects' in meal) {
          return meal.routineMetadataObjects?.some(
            (object) => object.marketingTitle.toLowerCase() === item.keyword
          )
        }
      } else if (meal[field]) {
        const value = meal[field]

        return value.toLowerCase().includes(item.keyword)
      }
    })
    return searchResults.includes(true)
  })

  if (validCookTime) {
    const cookTime = totalCookTime(meal.routine.routine)
    message = `Cook time of ${cookTime} not expected for this meal.`
    const isVariation =
      validCookTime.variation &&
      meal[validCookTime.variation.search] &&
      typeof meal[validCookTime.variation.search] === 'string' &&
      meal[validCookTime.variation.search]
        .toLowerCase()
        .includes(validCookTime.variation.keyword)
    const range =
      isVariation && validCookTime.variation
        ? validCookTime.variation.range
        : validCookTime.range
    let cookTimeIsValid = true

    if (!range[1]) {
      cookTimeIsValid = cookTime === range[0]
    } else {
      const minCookTime = cookTimeInSeconds(range[0])
      const maxCookTime = cookTimeInSeconds(range[1])
      const mealCookTimeInSeconds = cookTimeInSeconds(cookTime)
      cookTimeIsValid =
        mealCookTimeInSeconds >= minCookTime &&
        mealCookTimeInSeconds <= maxCookTime
    }

    return cookTimeIsValid ? undefined : message
  }
}

function validateIngredients(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  if (!meal.ingredients) {
    errors.push('ingredients is a required field')
  }

  errors.push(hasRequiredAllergensBasedOnIngredients(meal))
  errors.push(hasRequiredAllergenNoteBasedOnIngredients(meal))
  errors.push(hasBOXEXTRATag(meal))

  return errors
}

function validateSurchargeCents(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  if (titleContainsPremiumProtein(meal.title)) {
    errors.push(isExpectedSurcharge(meal))
  }

  errors.push(noSurchargeAndNotPremium(meal))
  errors.push(twoServingMealHasSurcharge(meal))

  return errors
}

async function validateImages(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  if (meal.images) {
    for (let i = 0; i < meal.images.length; i++) {
      const image = meal.images[i]

      if (!image.url) {
        errors.push(`images[${i}].url is a required field`)
      }

      if (!image.key) {
        errors.push(`images[${i}].key is a required field`)
      }

      errors.push(optionalGarnishImageHasNutritionalInfo({ image, meal }))

      errors.push(await imageLoads(image))
    }

    errors.push(hasCellTileImage(meal.images))
  }

  return errors
}

function validateMealAddOns(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  errors.push(dessertsHaveContainsDessertAddonTag(meal))
  errors.push(mealWithAddOnHasBoxextraTag(meal))
  errors.push(mealWithAddonHasSurcharge(meal))

  return errors
}

function validateRoutineMetadataObjects(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  if (!('routineMetadataObjects' in meal)) {
    return []
  }

  meal.routineMetadataObjects?.forEach((obj, index) => {
    if (!obj.marketingTitle) {
      errors.push(
        `routineMetadataObjects[${index}].marketingTitle is a required field`
      )
    }

    if (!obj.barcodeIdentifier) {
      errors.push(
        `routineMetadataObjects[${index}].barcodeIdentifier is a required field`
      )
    }

    if (!obj.routine) {
      errors.push(
        `routineMetadataObjects[${index}].routine is a required field`
      )
    }
  })

  if (shouldBeDualBarcodeMeal(meal.title) && !meal.routineMetadataObjects) {
    errors.push('Expected steak meal to have dual barcodes.')
  }

  errors.push(dualBarcodesHaveDifferentCookTimes(meal.routineMetadataObjects))

  return errors
}

function validateMealPrepSteps(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  if (!meal.mealPrepSteps) {
    errors.push('mealPrepSteps is a required field')
  }

  errors.push(stepsAreNumberedCorrectly(meal.mealPrepSteps))
  errors.push(sheetTrayMealHasTag(meal))

  return errors
}

function validateNutritionalInfo(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  meal.nutritionalInfo.forEach((info, index) => {
    if (!info.key) {
      errors.push(`nutritionalInfo[${index}].key is a required field`)
    }

    if (!info.name) {
      errors.push(`nutritionalInfo[${index}].key is a required field`)
    }

    if (info.key !== 'allergen' && !info.value) {
      errors.push(`Nutritional Info item ${info.name} is missing a value.`)
    }

    errors.push(optionalGarnishNutritionItemHasAnImage({ info, meal }))
    errors.push(saturatedFatIsLessThanTotalFat({ info, meal }))
    errors.push(sugarIsLessThanCarbs({ info, meal }))
  })

  errors.push(hasExpectedNutritionValues(meal))
  errors.push(noWheatAllergenHasGlutenFriendlyTag(meal))

  return errors
}

function validateTags(meal: TermMealCombined) {
  const errors: (string | undefined)[] = []

  meal.tags.forEach((tag, index) => {
    if (!tag.title) {
      errors.push(`tags[${index}].title is a required field`)
    }

    errors.push(glutenFriendlyTaggedHasWheatAllergen({ meal, tag }))
    errors.push(calorieSmartTaggedHasLessThan600Cal({ meal, tag }))
    errors.push(carbConsciousTaggedHas35gCarbsOrLess({ meal, tag }))
  })

  return errors
}

function validateListings({ listings }: { listings: ListingAdmin[] }) {
  const errors: (string | undefined)[] = []

  if (listings.some((listing) => listing.priceCents === 0)) {
    errors.push('No price set on one or more menu listings.')
  }

  if (listings.some((listing) => listing.expirationDate === null)) {
    errors.push('No expiration date set on one or more menu listings.')
  }

  return errors
}

function validateMenuMeals({
  meal,
  term,
}: {
  meal: TermMealCombined
  term: Term | TermListItem
}) {
  const errors: (string | undefined)[] = []

  if (!('menuMeals' in meal)) {
    return []
  }

  if (meal.menuMeals) {
    meal.menuMeals.forEach((menuMeal, index) => {
      if (!menuMeal.expirationDate) {
        errors.push(`menuMeals[${index}].expirationDate is a required field`)
      }

      if (!menuMeal.mainDisplayOrder) {
        errors.push(`menuMeals[${index}].mainDisplayOrder is a required field`)
      }

      if (!menuMeal.productionCode) {
        errors.push(`menuMeals[${index}].productionCode is a required field`)
      }

      errors.push(isExpectedExpirationDate({ menuMeal, term }))
    })
  } else {
    errors.push('No facility assigned')
  }

  return errors
}

async function validateMenuProductDetails({
  productDetailsURL,
}: {
  productDetailsURL: string
}) {
  const errors: (string | undefined)[] = []

  if (productDetailsURL !== '') {
    const menuProductDetails = await getMenuProductDetailsJSON({
      productDetailsURL,
    })

    if (menuProductDetails.componentImages.length === 0) {
      errors.push('product has no component images')
    }

    if (!menuProductDetails.ingredients) {
      errors.push('ingredients is a required field')
    }

    if (menuProductDetails.nutritionalInfo.length === 0) {
      errors.push('product has no nutritional information')
    } else {
      menuProductDetails.nutritionalInfo.forEach((info, index) => {
        if (!info.key) {
          errors.push(`nutritionalInfo[${index}].key is a required field`)
        }

        if (!info.name) {
          errors.push(`nutritionalInfo[${index}].key is a required field`)
        }

        if (info.key !== 'allergen' && !info.value) {
          errors.push(`Nutritional Info item ${info.name} is missing a value.`)
        }

        if (menuProductDetails.prepSteps.length === 0) {
          errors.push('product has no prep steps')
        }

        if (!menuProductDetails.story) {
          errors.push('story is a required field')
        }
      })
    }
  }

  return errors
}

async function validate({
  meal,
  term,
}: {
  meal: TermMealCombined
  term: Term | TermListItem
}) {
  const errors: (string | undefined)[] = []

  if (!meal.chef_image) {
    errors.push('chef_image is a required field')
  }

  if (!meal.created_by) {
    errors.push('created_by is a required field')
  }

  if (!meal.title) {
    errors.push('title is a required field')
  }

  if (!meal.story) {
    errors.push('story is a required field')
  }

  return compact([
    ...errors,
    ...validateIngredients(meal),
    ...validateSurchargeCents(meal),
    ...(await validateImages(meal)),
    validCookTimeForIngredient(meal),
    ...validateRoutineMetadataObjects(meal),
    ...validateMealAddOns(meal),
    ...validateMealPrepSteps(meal),
    ...validateNutritionalInfo(meal),
    ...validateTags(meal),
    ...validateMenuMeals({ meal, term }),
  ])
}

async function validateMenuProductListings({
  listings,
  menuProduct,
}: {
  listings: ListingAdmin[]
  menuProduct: MenuProduct
}) {
  const errors: (string | undefined)[] = []

  if (!menuProduct.title) {
    errors.push('title is a required field')
  }

  if (!menuProduct.imageURL) {
    errors.push('main image is required')
  }

  if (!menuProduct.productDetailsURL) {
    errors.push('product details have not been set for the associated product')
  }

  return compact([
    ...errors,
    ...validateListings({ listings }),
    ...(await validateMenuProductDetails({
      productDetailsURL: menuProduct.productDetailsURL,
    })),
  ])
}

const TermValidator = ({
  summaryView,
  term,
  termListings,
  termProducts,
}: {
  summaryView: boolean
  term: Term | TermListItem
  termListings: ListingAdmin[]
  termProducts: ListingAdmin[]
}): JSX.Element => {
  const [mealsErrors, setMealsErrors] = useState<MealErrors[]>([])
  const [menuProductsErrors, setMenuProductsErrors] = useState<
    MenuProductErrors[]
  >([])
  const [showModal, setShowModal] = useState(false)
  const [validMealIDs, setValidMealIDs] = useState<number[]>([])
  const [validMenuProductIDs, setValidMenuProductIDs] = useState<string[]>([])

  useEffect(() => {
    const termMeals: TermMealCombined[] = term.meals ?? []

    termMeals.forEach((meal) => {
      validate({ meal, term }).then((validationErrors) => {
        if (validationErrors.length > 0) {
          setMealsErrors((errors) => {
            const mealIndex = errors.map((error) => error.id).indexOf(meal.id)

            if (mealIndex > -1) {
              const updatedErrors = [...errors]

              updatedErrors[mealIndex].errors = validationErrors

              return updatedErrors
            }

            return [...errors, { id: meal.id, errors: validationErrors }]
          })
        } else {
          setValidMealIDs((mealIDs) => {
            return [...mealIDs, meal.id]
          })
        }
      })
    })
  }, [term])

  useEffect(() => {
    termProducts.forEach((listing) => {
      const menuProduct: MenuProduct = {
        barcode: listing.barcode,
        id: listing.productID,
        imageURL: listing.imageURL,
        productDetailsURL: listing.productDetailsURL,
        title: listing.title,
      }

      const listings = termListings.filter(
        (listing) => listing.productID === menuProduct.id
      )
      validateMenuProductListings({ menuProduct, listings }).then(
        (validationErrors) => {
          if (validationErrors.length > 0) {
            setMenuProductsErrors((errors) => {
              const menuProductIndex = errors
                .map((error) => error.id)
                .indexOf(menuProduct.id)

              if (menuProductIndex > -1) {
                const updatedErrors = [...errors]

                updatedErrors[menuProductIndex].errors = validationErrors

                return updatedErrors
              }

              return [
                ...errors,
                { id: menuProduct.id, errors: validationErrors },
              ]
            })
          } else {
            setValidMenuProductIDs((menuProductIDs) => {
              return [...menuProductIDs, menuProduct.id]
            })
          }
        }
      )
    })
  }, [termListings, termProducts])

  let numberOfMealErrors = 0
  let numberOfMenuProductErrors = 0
  if (mealsErrors.length > 0) {
    numberOfMealErrors = mealsErrors.reduce((currentNumErrors, errorsObj) => {
      return currentNumErrors + (errorsObj.errors?.length ?? 0)
    }, 0)
  }
  if (menuProductsErrors.length > 0) {
    numberOfMenuProductErrors = menuProductsErrors.reduce(
      (currentNumErrors, errorsObj) => {
        return currentNumErrors + (errorsObj.errors?.length ?? 0)
      },
      0
    )
  }

  const numberOfErrors = numberOfMealErrors + numberOfMenuProductErrors

  const termMeals: TermMealCombined[] = term.meals ?? []

  return (
    <div className="mt-2">
      {summaryView && (
        <div
          onClick={() => {
            setShowModal(true)
          }}
        >
          {mealsErrors.length > 0 && (
            <div className="flex items-center space-x-4">
              <span className="text-sm font-semibold text-red-901">
                {numberOfErrors} problem{numberOfErrors === 1 ? '' : 's'} with
                menu data on Term #{term.id} found.
              </span>
              <Button buttonStyle="grey" size="large" type="button">
                View
              </Button>
            </div>
          )}
        </div>
      )}

      {showModal && (
        <Modal
          onCloseModal={() => {
            setShowModal(false)
          }}
        >
          <ModalBody>
            <ModalHeader
              onClickClose={() => {
                setShowModal(false)
              }}
            >
              Term #{term.id} Menu Validator
            </ModalHeader>

            {termMeals.map((meal, i) => {
              const image = meal.images?.find(
                (image) => image.key === 'cell_tile'
              )
              let isValid: boolean | '' = ''
              if (validMealIDs.length) {
                isValid = validMealIDs.some((mealID) => mealID === meal.id)
              }
              let mealErrors: MealErrors | '' | undefined = ''
              if (mealsErrors.length) {
                mealErrors = mealsErrors.find((m) => m.id === meal.id)
              }

              return (
                <Fragment key={i}>
                  <div className="mb-4 flex">
                    <div className="w-2/12">
                      {image && image.url && (
                        <img src={`https://${image.url}`} width="200" />
                      )}
                    </div>
                    <div className="w-4/12">
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        to={`/meals/${meal.id}`}
                      >
                        <p>
                          {meal.title} (Meal ID #{meal.id})
                        </p>
                      </Link>
                      <p className="text-sm">{meal.subtitle}</p>
                    </div>
                    <div className="w-6/12">
                      {isValid && (
                        <p className="text-green-907">No problems found</p>
                      )}
                      {mealErrors &&
                        mealErrors.errors &&
                        mealErrors.errors.map((err, i) => (
                          <p key={i} className="text-red-901">
                            {err}
                          </p>
                        ))}
                    </div>
                  </div>
                  <Hr />
                </Fragment>
              )
            })}

            {termProducts.map((listing, i) => {
              let isValid: boolean | '' = ''
              if (validMenuProductIDs.length) {
                isValid = validMenuProductIDs.some(
                  (menuProductID) => menuProductID === listing.productID
                )
              }
              let menuProductErrors: MenuProductErrors | '' | undefined = ''
              if (menuProductsErrors.length) {
                menuProductErrors = menuProductsErrors.find(
                  (p) => p.id === listing.productID
                )
              }

              return (
                <Fragment key={i}>
                  <div className="mb-4 flex">
                    <div className="w-2/12">
                      {listing.imageURL && (
                        <img src={listing.imageURL} width="200" />
                      )}
                    </div>
                    <div className="w-4/12">
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        to={`/terms/${term.id}/menu-products/${listing.productID}`}
                      >
                        <p>{listing.title}</p>
                      </Link>
                    </div>
                    <div className="w-6/12">
                      {isValid && (
                        <p className="text-green-907">No problems found</p>
                      )}
                      {menuProductErrors &&
                        menuProductErrors.errors &&
                        menuProductErrors.errors.map((err, i) => (
                          <p key={i} className="text-red-901">
                            {err}
                          </p>
                        ))}
                    </div>
                  </div>
                  <Hr />
                </Fragment>
              )
            })}
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

export default TermValidator
