import { createReducer } from '@reduxjs/toolkit'
import {
  PublicCalendarTerms,
  Term,
  TermListItem,
  TermMenu,
  TermMenuUpdated,
} from '@tovala/browser-apis-combinedapi'

import * as types from '../actions/types'

const INITIAL_STATE: {
  allTerms: TermListItem[]
  publicCalendar: PublicCalendarTerms | ''
  term:
    | Term
    | {
        meals: []
      }
  selectedMenu: TermMenu | TermMenuUpdated | ''
  selectedTerm:
    | Term
    | {
        meals: []
      }
} = {
  allTerms: [],
  publicCalendar: '',
  term: {
    meals: [],
  },
  selectedMenu: '',
  selectedTerm: {
    meals: [],
  },
}

export const terms = createReducer(INITIAL_STATE, {
  [types.GET_ALL_TERMS]: (state, action) => {
    state.allTerms = action.payload
  },
  [types.GET_TERM]: (state, action) => {
    state.selectedTerm = action.payload
  },
  [types.GET_NEXT_TERM]: (state, action) => {
    state.term = action.payload
  },
  [types.GET_PUBLIC_CALENDAR]: (state, action) => {
    state.publicCalendar = action.payload
  },
  [types.GET_MENU]: (state, action) => {
    state.selectedMenu = action.payload
  },
})
