import { MouseEvent as MouseEventType } from 'react'
import { orderBy, sortBy, uniqBy } from 'lodash-es'
import { useParams } from 'react-router-dom'

import { getMealCameraUrl, makeMenuProductQRCodeValue } from 'utils/meals'

import {
  ErrorCodeMessageMapCombinedAPI,
  ListingAdmin,
  useTerm,
} from '@tovala/browser-apis-combinedapi'
import { APIErrorDisplay, Button } from '@tovala/component-library'
import { useTermMenuListings } from 'hooks/menuProducts'
import QRCode from 'components/common/QRCode'
import Loader from 'components/common/Loader'

const GET_LISTINGS_FOR_MENUS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Listings for menus',
    why: "We couldn't load listings for this term due to a technical issue on our end.",
  },
}

const LOAD_TERM_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Term',
    why: "We couldn't load the term due to a technical issue on our end.",
  },
}

const TermMealQRCodes = (): JSX.Element => {
  const { termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const {
    isLoading: isLoadingTerm,
    error: loadTermError,
    isError: hasLoadTermError,
    data: term,
  } = useTerm({ termID })

  const {
    error: loadTermMenuListingsError,
    isError: hasLoadTermMenuListingsError,
    data: termMenuListings,
  } = useTermMenuListings({ termID })

  const allListingsForMenus = termMenuListings
    .map((menuListings) => {
      return menuListings?.listings
    })
    .filter((listings): listings is ListingAdmin[] => !!listings)

  const uniqueProducts = orderBy(
    uniqBy(allListingsForMenus.flat(), 'productID'),
    'productionCode'
  )

  if (isLoadingTerm) {
    return <Loader />
  }

  if (hasLoadTermError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadTermError}
        errorCodeMessageMap={LOAD_TERM_ERRORS}
      />
    )
  }

  const download = ({
    qrCode,
    filename,
  }: {
    qrCode: HTMLCanvasElement
    filename: string
  }) => {
    qrCode.toBlob((blob) => {
      if (blob) {
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)

        link.href = url
        link.download = `${filename}.png`
        link.style.display = 'none'
        const evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })

        document.body.appendChild(link)
        link.dispatchEvent(evt)
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }
    })
  }

  const downloadAll = () => {
    const allQRCodes = Array.from(document.getElementsByTagName('canvas'))

    let count = 0
    allQRCodes.forEach((qrCode) => {
      const parentElement: HTMLDivElement | null =
        qrCode.closest('[data-filename]')
      const filename = parentElement?.dataset.filename || ''

      count += 500
      window.setTimeout(() => {
        download({ qrCode, filename })
      }, count)
    })
  }

  const handleQRCodeClick = (
    event: MouseEventType<HTMLDivElement>,
    { filename }: { filename: string }
  ) => {
    const target = event.target as HTMLElement
    if (target.tagName?.toLowerCase() === 'canvas') {
      const qrCode = event.target as HTMLCanvasElement
      download({ qrCode, filename })
    }
  }

  return (
    <div className="font-sans-new">
      <div className="flex justify-between items-center">
        <h1 className="text-k/36_110">Term #{term.id} QR Codes</h1>

        <div className="my-4">
          <Button onClick={downloadAll} size="large">
            Download All
          </Button>
        </div>
      </div>

      {hasLoadTermMenuListingsError && (
        <APIErrorDisplay
          error={loadTermMenuListingsError}
          errorCodeMessageMap={GET_LISTINGS_FOR_MENUS_ERRORS}
        />
      )}

      <div className="space-y-6">
        <h2 className="text-k/28_110">Meals</h2>

        <div className="flex flex-wrap">
          {[...term.meals]
            .sort((a, b) => a.id - b.id)
            .map((meal) => {
              if (meal.routineMetadataObjects) {
                return sortBy(
                  meal.routineMetadataObjects,
                  (routine) => routine.barcodeIdentifier
                ).map((routine) => {
                  // It's only helpful to the menu team to display marketing titles
                  // when there's more than one QR code per meal
                  const marketingTitle =
                    meal.routineMetadataObjects &&
                    meal.routineMetadataObjects.length > 1
                      ? routine.marketingTitle
                      : ''

                  const filename = `${meal.id}${
                    marketingTitle ? `-${marketingTitle}` : ''
                  }`
                  const value = getMealCameraUrl({
                    barcodeIdentifier: routine.barcodeIdentifier,
                    mealID: meal.id,
                  })
                  return (
                    <div
                      key={`${meal.id}-${routine.barcodeIdentifier}`}
                      className="m-2 w-[180px] cursor-pointer"
                      data-filename={filename}
                      onClick={(event) =>
                        handleQRCodeClick(event, {
                          filename,
                        })
                      }
                      title={value}
                    >
                      <QRCode value={value} />
                      <p className="cursor-default text-center">
                        {meal.id} - {meal.title}{' '}
                        {marketingTitle && (
                          <span className="uppercase">({marketingTitle})</span>
                        )}
                      </p>
                    </div>
                  )
                })
              }
            })}
        </div>
      </div>

      {uniqueProducts.length > 0 && (
        <div className="space-y-6">
          <h2 className="text-k/28_110">Extras</h2>

          <div className="flex flex-wrap">
            {uniqueProducts.map((product) => {
              if (product.barcode) {
                const { shortProductID, value } = makeMenuProductQRCodeValue(
                  product.barcode,
                  term.id
                )
                const filename = `T${term.id}-${shortProductID}-${product.title}`

                return (
                  <div
                    key={product.productID}
                    className="m-2 w-[180px] cursor-pointer"
                    data-filename={filename}
                    onClick={(event) =>
                      handleQRCodeClick(event, {
                        filename,
                      })
                    }
                    title={value}
                  >
                    <QRCode value={value} />
                    <p className="cursor-default text-center">
                      T{term.id} - {shortProductID} - {product.title}
                    </p>
                  </div>
                )
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default TermMealQRCodes
