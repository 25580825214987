import { FieldArray, Form, Formik, useField } from 'formik'
import ReactSelect from 'react-select'
import {
  Listing,
  Term,
  useMenuListingsLayout,
} from '@tovala/browser-apis-combinedapi'
import { useEffect } from 'react'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { hasMealTag, TAG_IDS } from 'utils/meals'
import { getPublicCalendar } from '../../actions/terms'
import { ReactSelectValue } from 'types/internal'

import { useAppDispatch, useAppSelector } from 'hooks'
import H1 from 'components/common/H1'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { useMenuProductDetailsJSON } from '@tovala/browser-apis-cdn'
import { flatMap } from 'lodash-es'

interface FormData {
  upcomingMeals: (number | undefined)[]
}

const Select = (props: {
  name: string
  onChange(newOption: ReactSelectValue<number> | null): void
  options: ReactSelectValue<number>[]
  value: ReactSelectValue<number> | null
}): JSX.Element => {
  const [field] = useField({ name: props.name })

  return <ReactSelect {...field} {...props} />
}

const UpcomingMealsEmailData = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const publicCalendar = useAppSelector((state) => state.terms.publicCalendar)

  useEffect(() => {
    document.title = `Glaze | Upcoming Meals Email Data`
  }, [])

  useEffect(() => {
    if (!publicCalendar) {
      dispatch(getPublicCalendar())
    }
  }, [dispatch, publicCalendar])

  let termsWithMeals: Term[] = []
  if (publicCalendar && publicCalendar.present) {
    termsWithMeals = [
      publicCalendar.present.term,
      ...publicCalendar.future.terms,
    ].filter((term) => term.meals.length)
  }

  return (
    <div>
      <H1>Upcoming Meals Email Data</H1>

      <TabGroup>
        <TabList>
          {termsWithMeals.map((term) => (
            <Tab key={`tab-${term.id}`}>Term #{term.id}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {termsWithMeals.map((term) => {
            return <TermTabPanel key={term.id} term={term} />
          })}
        </TabPanels>
      </TabGroup>
    </div>
  )
}

export default UpcomingMealsEmailData

const TermTabPanel = ({ term }: { term: Term }) => {
  const subTerm = term.subTerms.find(
    (subTerm) =>
      subTerm.shipPeriod === 1 && subTerm.facilityNetwork === 'chicago'
  )

  const { data: menuListingsLayout } = useMenuListingsLayout({
    menuID: subTerm?.defaultMenu.id,
  })
  const listings = flatMap(
    menuListingsLayout?.sections.map((section) => section.listings) ?? []
  )

  if (!subTerm) {
    return null
  }

  const meals = subTerm.defaultMenu.meals
    ? [...subTerm.defaultMenu.meals].sort(
        (a, b) => a.mainDisplayOrder - b.mainDisplayOrder
      )
    : []
  const mealSwaps = subTerm.defaultMenu.mealSwaps
    ? subTerm.defaultMenu.mealSwaps
    : []
  const primaryMeals = mealSwaps.map((mealSwap) => mealSwap.mealID)
  const swapMeals = mealSwaps.map((mealSwap) => mealSwap.swapIDs).flat()
  const initialValues: FormData = {
    upcomingMeals: Array.from({ length: 6 }),
  }
  const mealOptions = [...meals]
    .sort((a, b) => a.id - b.id)
    .map((meal) => {
      return {
        value: meal.id,
        label: `${meal.id} - ${meal.title} ${meal.subtitle}`,
      }
    })

  return (
    <TabPanel key={`tab-panel-${term.id}`}>
      <h5 className="mb-4">
        {formatDate(term.start, {
          format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
        })}{' '}
        &mdash;{' '}
        {formatDate(term.end, {
          format: DATE_FORMATS.DOW_MONTH_ABBR_DAY_YEAR,
        })}
      </h5>

      <TabGroup>
        <TabList tabStyle="pills">
          <Tab tabStyle="pills">Select Meals</Tab>
          <Tab tabStyle="pills">All Meals</Tab>
          <Tab tabStyle="pills">All Extras</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Formik<FormData>
              enableReinitialize
              initialValues={initialValues}
              onSubmit={() => {
                throw new Error('This form is not intended to be submitted.')
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <Form>
                    <div>
                      <FieldArray
                        name="upcomingMeals"
                        render={() => (
                          <div className="space-y-2">
                            {values.upcomingMeals.length &&
                              values.upcomingMeals.map((_mealID, index) => {
                                const number = index + 1
                                return (
                                  <div
                                    key={`select-${number}`}
                                    className="flex"
                                  >
                                    <label className="w-20">#{number}</label>

                                    <div className="grow">
                                      <Select
                                        name={`upcomingMeals.${index}`}
                                        onChange={(option) => {
                                          setFieldValue(
                                            `upcomingMeals.${index}`,
                                            option?.value ?? undefined
                                          )
                                        }}
                                        options={mealOptions}
                                        value={
                                          mealOptions.find(
                                            (option) =>
                                              option.value ===
                                              values.upcomingMeals[index]
                                          ) ?? null
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        )}
                      />
                      <div>
                        {values.upcomingMeals.length &&
                          values.upcomingMeals.map((mealID, index) => {
                            const meal = term.meals.find(
                              (meal) => meal.id === mealID
                            )
                            const i = index + 1
                            const image = meal
                              ? meal.images.find(
                                  (image) => image.key === 'cell_tile'
                                )
                              : ''
                            const imageURL = image ? image.url : ''

                            return (
                              <div
                                key={`select${i}-${mealID}`}
                                className="whitespace-pre-line"
                              >
                                {meal &&
                                  `
                          {{#assign "meal${i}title"}}${meal.title}{{/assign}}
                          {{#assign "meal${i}subtitle"}}${meal.subtitle}{{/assign}}
                          {{#assign "meal${i}image"}}https:${imageURL}{{/assign}}
                          {{#assign "meal${i}id"}}${meal.id}{{/assign}}
                      `}
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </TabPanel>

          <TabPanel>
            {meals.map((meal, index) => {
              const i = index + 1
              const image = meal.images.find(
                (image) => image.key === 'cell_tile'
              )
              const imageURL = image ? image.url : ''
              let customizeIt = 0
              if (primaryMeals.includes(meal.id)) {
                customizeIt = 1
              } else if (swapMeals.includes(meal.id)) {
                customizeIt = 2
              }

              return (
                <div key={`all${i}-${meal.id}`} className="whitespace-pre-line">
                  {`
            {{#assign "meal${i}title"}}${meal.title}{{/assign}}
            {{#assign "meal${i}subtitle"}}${meal.subtitle}{{/assign}}
            {{#assign "meal${i}image"}}https:${imageURL}{{/assign}}
            {{#assign "meal${i}id"}}${meal.id}{{/assign}}
            {{#assign "meal${i}vegetarian"}}${
                    hasMealTag(meal.tags, TAG_IDS.VEGETARIAN) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}glutenfree"}}${
                    hasMealTag(meal.tags, TAG_IDS.GLUTEN_FRIENDLY)
                      ? 'YES'
                      : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}caloriesmart"}}${
                    hasMealTag(meal.tags, TAG_IDS.CALORIE_SMART) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}carbconscious"}}${
                    hasMealTag(meal.tags, TAG_IDS.CARB_CONSCIOUS) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}customizeit"}}${customizeIt}{{/assign}}
            {{#assign "meal${i}proteinchoice"}}${
                    hasMealTag(meal.tags, TAG_IDS.PROTEIN_CHOICE) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}premium"}}${
                    hasMealTag(meal.tags, TAG_IDS.PREMIUM) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}dualserving"}}${
                    hasMealTag(meal.tags, TAG_IDS.DUAL_SERVING) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}breakfast"}}${
                    hasMealTag(meal.tags, TAG_IDS.BREAKFAST) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}spicy"}}${
                    hasMealTag(meal.tags, TAG_IDS.SPICY) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}containsdessertaddon"}}${
                    hasMealTag(meal.tags, TAG_IDS.CONTAINS_DESSERT_ADD_ON)
                      ? 'YES'
                      : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}addonmeal"}}${
                    hasMealTag(meal.tags, TAG_IDS.ADD_ON_MEAL) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}bestseller"}}${
                    hasMealTag(meal.tags, TAG_IDS.BEST_SELLER) ? 'YES' : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}appetizerandentree"}}${
                    hasMealTag(meal.tags, TAG_IDS.APPETIZER_AND_ENTREE)
                      ? 'YES'
                      : 'NO'
                  }{{/assign}}
            {{#assign "meal${i}new"}}${
                    hasMealTag(meal.tags, TAG_IDS.NEW) ||
                    hasMealTag(meal.tags, TAG_IDS.NEW_BANNER)
                      ? 'YES'
                      : 'NO'
                  }{{/assign}}
         `}
                </div>
              )
            })}
          </TabPanel>

          <TabPanel>
            <div>
              {listings.map((listing, index) => {
                return (
                  <Extra
                    key={listing.id}
                    listing={listing}
                    listingIndex={index}
                  />
                )
              })}
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </TabPanel>
  )
}

const Extra = ({
  listing,
  listingIndex,
}: {
  listing: Listing
  listingIndex: number
}) => {
  const i = listingIndex + 1

  const { data: listingDetails } = useMenuProductDetailsJSON({
    productDetailsURL: listing.productDetailsURL,
  })

  return (
    <div className="whitespace-pre-line">
      {`
    {{#assign "extra${i}title"}}${listing.title}{{/assign}}
    {{#assign "extra${i}subtitle"}}${listingDetails?.subtitle}{{/assign}}
    {{#assign "extra${i}image"}}${listing.imageURL}{{/assign}}
    {{#assign "extra${i}id"}}${listing.id}{{/assign}}
    `}
    </div>
  )
}
