import {
  AssistRecipe,
  Oven,
  OvenCookStatus,
  PublicCalendarTerms,
  Term,
  useAssistRecipes,
  useDeleteOven,
  useInvalidateOvens,
  useOven,
  useOvenCookHistory,
  useOvenCookStatus,
  useTransferOven,
  useUserV0,
  User as CombinedAPIUser,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import Pusher from 'pusher-js'
import { ReactNode, useEffect, useState } from 'react'

import { errorHandler, successHandler } from 'actions/notifications'
import { getAdminScope, CS_OVEN } from '../../utils/getAdminScope'
import { getEnvVar } from 'utils/env'
import { getOvenModelMarketingName } from 'utils/ovens'
import { getPublicCalendar } from '../../actions/terms'
import { getUserInfo } from '../../actions/auth'
import { isAxiosResponseError } from 'utils/api'

import { useAppDispatch, useAppSelector } from 'hooks'
import AlertInline from 'components/common/AlertInline'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import H2 from 'components/common/H2'
import Hr from 'components/common/Hr'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import Table, { TableRow, TBody, TD, TH, THead } from 'components/common/Table'

const pusher = new Pusher(getEnvVar('PUSHER_APP_KEY'), {
  cluster: 'mt1',
})

const TransferOvenModal = ({
  deviceid,
  onCloseModal,
  user,
}: {
  deviceid: string
  onCloseModal(): void
  user: UserV1
}): JSX.Element => {
  const [newUserID, setNewUserID] = useState('')

  const {
    data: newUser,
    error: getNewUserError,
    isError: hasGetNewUserError,
    isLoading: isLoadingNewUser,
  } = useUserV0({
    refetchOnWindowFocus: false,
    retry: false,
    userID: newUserID ? Number(newUserID) : undefined,
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>Transfer Oven</ModalHeader>
        <div className="w-[600px] max-w-full">
          {newUser ? (
            <TransferOvenConfirmNewUser
              currentUser={user}
              deviceID={deviceid}
              newUser={newUser}
              onClickCancel={onCloseModal}
            />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault()

                const userID = event.currentTarget.elements.namedItem('userID')
                if (userID && userID instanceof HTMLInputElement) {
                  setNewUserID(userID.value)
                } else {
                  throw new Error(
                    'No userID form element for fetching new user info for transferring an oven.'
                  )
                }
              }}
            >
              <div className="space-y-2">
                <div>
                  <strong>New Account User #</strong>
                  <div className="flex space-x-4">
                    <Input name="userID" type="number" />
                    <ButtonLoading
                      buttonStyle="grey"
                      isLoading={!!newUserID && isLoadingNewUser}
                      size="large"
                      type="submit"
                    >
                      Continue
                    </ButtonLoading>
                  </div>
                </div>

                {hasGetNewUserError && (
                  <AlertInline alertStyle="danger">
                    <p className="font-bold">
                      There was an error retrieving the new user:{' '}
                      {isAxiosResponseError(getNewUserError)
                        ? getNewUserError.response?.data.message
                        : getNewUserError.message}
                    </p>
                  </AlertInline>
                )}
              </div>
            </form>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

const TransferOvenConfirmNewUser = ({
  currentUser,
  deviceID,
  newUser,
  onClickCancel,
}: {
  currentUser: UserV1
  deviceID: string
  newUser: CombinedAPIUser
  onClickCancel(): void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isLoading: isTransferringOven, mutate: transferOven } =
    useTransferOven({
      onError: (error) => {
        errorHandler(dispatch, error)
      },
      onSuccess: (_, { data }) => {
        const { newUserID } = data

        successHandler(
          dispatch,
          `Success! Oven transfered to User #${newUserID}`
        )
        navigate(`/user/${newUserID}/account-overview`)
      },
    })

  return (
    <div>
      <p className="mb-4 font-bold">
        Are you sure you want to transfer this oven?
      </p>
      <div className="flex space-x-8">
        <div className="w-6/12">
          <p className="font-bold">New Account</p>
          {newUser.name && <p>{newUser.name}</p>}
          <p className="truncate">{newUser.email}</p>
          <p>User #{newUser.id}</p>
        </div>
        <div className="w-6/12">
          <p className="font-bold">Current Account</p>
          {currentUser.info.name && <p>{currentUser.info.name}</p>}
          <p className="truncate">{currentUser.info.email}</p>
          <p>User #{currentUser.id}</p>
        </div>
      </div>
      <div className="mt-4 flex space-x-2">
        <ButtonLoading
          isLoading={isTransferringOven}
          onClick={() => {
            transferOven({
              data: { newUserID: newUser.id, deviceID },
              userID: currentUser.id,
            })
          }}
          size="large"
        >
          Complete Transfer
        </ButtonLoading>
        <Button buttonStyle="cancel" onClick={onClickCancel} size="large">
          Cancel
        </Button>
      </div>
    </div>
  )
}

const UnlinkOvenModal = ({
  onCloseModal,
  onOvenUnlinked,
  oven,
  userid,
}: {
  onCloseModal(): void
  onOvenUnlinked(): void
  oven: Oven
  userid: number
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [unlinkSecret, setUnlinkSecret] = useState('')

  const { isLoading: isDeletingOven, mutate: deleteOven } = useDeleteOven({
    onError: (error) => {
      errorHandler(dispatch, error)
    },
    onSuccess: () => {
      successHandler(dispatch, 'Success! Oven registration removed.')
      onOvenUnlinked()
    },
  })

  function handleClick() {
    // Unlink oven API call
    if (unlinkSecret === 'goodbyeoven') {
      deleteOven({ ovenID: oven.id, userID: userid })
    } else {
      alert('The password was not correct.')
      onCloseModal()
    }
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>Unlink Oven</ModalHeader>
        <div>
          <H2>Oven: {oven.name} - This action is restricted</H2>

          <p className="mb-4 font-bold">
            Are you sure you want to remove registration info and unlink this
            oven?
          </p>

          <Input
            onChange={(event) => {
              setUnlinkSecret(event.target.value)
            }}
            placeholder="an adminstrator password is needed"
            type="password"
            value={unlinkSecret}
          />

          <div className="mt-4">
            <ButtonLoading
              isLoading={isDeletingOven}
              onClick={handleClick}
              size="large"
            >
              Unlink
            </ButtonLoading>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const Imp = (): JSX.Element => {
  const { ovenid, userid } = useParams<{ ovenid: string; userid: string }>()
  const [searchParams] = useSearchParams()
  const sourceParam = searchParams.get('source')
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const publicCalendar = useAppSelector((state) => state.terms.publicCalendar)
  const user = useAppSelector((state) => state.auth.user)

  const [isTransferOvenModalOpen, setIsTransferOvenModalOpen] = useState(false)
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false)

  const userID = user?.id

  const { data: oven } = useOven({ ovenID: ovenid, userID })

  const sources = { elasticsearch: 'elasticsearch', dynamodb: 'dynamodb' }
  const source = sourceParam === null ? undefined : sources[sourceParam]

  const { data: ovenCookHistory = [] } = useOvenCookHistory({
    ovenID: ovenid,
    userID,
    source,
  })

  const { data: assistRecipes = [] } = useAssistRecipes()

  function getAllMealsFromCalendar(calendar: PublicCalendarTerms) {
    let meals: Term['meals'] = []
    const pastMeals = calendar.past.terms
      .map((term) => term.meals.map((meal) => meal))
      .flat()
    const presentMeals = calendar.present.term.meals.map((meal) => meal)
    const futureMeals = calendar.future.terms
      .map((term) => term.meals.map((meal) => meal))
      .flat()

    meals = [...pastMeals, ...presentMeals, ...futureMeals]

    return meals
  }

  const getMacAddress = (deviceid: string) => {
    // MAC address is inferred from the device ID
    // 30000c2a690bed57 = 0c:2a:69:0b:ed:57
    // Remove first 4 characters, split into array of 2 character strings, then join with colons
    return deviceid.substring(4).match(/.{2}/g)?.join(':')
  }

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Oven`
  }, [userid])

  useEffect(() => {
    if (userid) {
      dispatch(getUserInfo(userid))
    }
  }, [dispatch, userid])

  useEffect(() => {
    if (!publicCalendar) {
      dispatch(getPublicCalendar())
    }
  }, [dispatch, publicCalendar])

  let deviceid = ''
  let agentid = ''
  let serial = 'Unknown'

  if (oven) {
    deviceid = oven[oven.type].deviceid
    agentid = oven[oven.type].agentid
    serial = oven[oven.type]?.serial ?? 'Unknown'
  }

  // Get meals from recent terms to match up meal titles with barcodes (removed getAllMeals because it makes the API angry)
  const allMealsFromCalendar = publicCalendar
    ? getAllMealsFromCalendar(publicCalendar)
    : []

  return (
    <div className="flex space-x-4">
      <div className="w-3/12">
        {user && (
          <div>
            {user.info.name && (
              <Link to={`/user/${userid}/account-overview`}>
                <H2>{user.info.name}</H2>
              </Link>
            )}
            <p>{user.info.email}</p>
            <Hr />
          </div>
        )}

        <div className="rounded border border-grey-900">
          {user && oven && (
            <div>
              <div className="rounded-t bg-black-903 px-4 py-3 text-white-900">
                <H2>{oven.name}</H2>
                <p className="font-bold">{getOvenModelMarketingName(oven)}</p>
              </div>
              {getAdminScope(CS_OVEN) && deviceid && (
                <div>
                  <button className="w-full bg-red-901 px-4 py-2 text-left text-sm uppercase tracking-widest">
                    <a
                      className="text-white-900"
                      href={`https://drizzle.tvla.co/pestle/cs.html?search=${deviceid}`}
                    >
                      Troubleshoot Oven
                    </a>
                  </button>
                </div>
              )}
              {getAdminScope(CS_OVEN) && deviceid && (
                <div>
                  <button
                    className="w-full bg-grey-903 px-4 py-2 text-left text-sm uppercase tracking-widest "
                    onClick={() => {
                      setIsTransferOvenModalOpen(true)
                    }}
                  >
                    Transfer Oven to New Account
                  </button>
                </div>
              )}

              {isTransferOvenModalOpen && (
                <TransferOvenModal
                  deviceid={deviceid}
                  onCloseModal={() => {
                    setIsTransferOvenModalOpen(false)
                  }}
                  user={user}
                />
              )}

              <div className="space-y-4 p-4 px-8">
                <div>
                  <p>
                    <strong>Device ID</strong>
                  </p>
                  {deviceid && <p>{deviceid}</p>}
                </div>
                <div>
                  <p>
                    <strong>Serial Number</strong>
                  </p>
                  {serial && <p>{serial}</p>}
                </div>
                <div>
                  <p>
                    <strong>MAC Address </strong>
                  </p>
                  <p>{deviceid && getMacAddress(deviceid)}</p>
                </div>

                {ovenid && userID && (
                  <OvenCurrentState ovenID={ovenid} userID={userID} />
                )}

                {agentid && (
                  <div>
                    <p>
                      <strong>Oven Logs</strong>
                    </p>
                    <p>
                      <a
                        href={`http://aegean.tovala.com:3000/dashboard/db/device-temperature-monitor?var-agentID=${agentid}`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Temperature Logs
                      </a>
                    </p>
                  </div>
                )}

                <div>
                  {isUnlinkModalOpen && (
                    <UnlinkOvenModal
                      onCloseModal={() => {
                        setIsUnlinkModalOpen(false)
                      }}
                      onOvenUnlinked={() => {
                        navigate(`/user/${user.id}/account-overview`)
                      }}
                      oven={oven}
                      userid={oven.userid}
                    />
                  )}

                  <Button
                    buttonStyle="grey"
                    onClick={() => {
                      setIsUnlinkModalOpen(true)
                    }}
                    size="large"
                  >
                    Unlink Oven
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-9/12 text-sm">
        {ovenCookHistory.length > 0 && (
          <Table>
            <THead>
              <TableRow>
                <TH>Start Time</TH>
                <TH>End Time</TH>
                <TH>Barcode/Mode</TH>
                <TH>Status</TH>
                <TH>Cook Cycle ID</TH>
              </TableRow>
            </THead>
            <TBody>
              {ovenCookHistory.map((row, index) => {
                let startTime = ''
                if (row.start_time) {
                  startTime = moment(row.start_time).format(
                    'h:mm A [on] MMM DD, YYYY'
                  )
                }
                let endTime = ''
                if (row.end_time) {
                  endTime = moment(row.end_time).format(
                    'h:mm A [on] MMM DD, YYYY'
                  )
                }

                const barcode = row.barcode
                const mealID = row.meal_id
                let meal:
                  | AssistRecipe
                  | Term['meals'][number]
                  | ''
                  | undefined = ''
                let title = ''

                if (barcode) {
                  const barcodeIdentifer = '133A254'
                  if (barcode.includes('ASSIST')) {
                    meal = assistRecipes.find(
                      (recipe) => recipe.barcode === barcode
                    )
                  } else if (barcode.includes(barcodeIdentifer)) {
                    const barcodeMealId = Number.parseInt(
                      barcode.split('|')[1],
                      10
                    )
                    meal = allMealsFromCalendar.find(
                      (meal) => meal.id === barcodeMealId
                    )
                  }
                }

                if (mealID) {
                  meal = allMealsFromCalendar.find(
                    (meal) => meal.id === Number(mealID)
                  )
                }

                if (meal) {
                  title = meal.title
                }

                return (
                  <TableRow key={index} highlight>
                    <TD>{startTime}</TD>
                    <TD>{endTime}</TD>
                    <TD>
                      {title ? (
                        <p>
                          <p>{title}</p>
                          <p className="text-xs text-grey-905">
                            {barcode}
                            {!barcode && mealID && (
                              <span>Meal ID #{mealID}</span>
                            )}
                          </p>
                        </p>
                      ) : (
                        <span>
                          {barcode}
                          {!barcode && mealID && <span>Meal ID #{mealID}</span>}
                        </span>
                      )}
                    </TD>
                    <TD>{row.status}</TD>
                    <TD>{row.cook_cycle_id}</TD>
                  </TableRow>
                )
              })}
            </TBody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default Imp

const OvenCurrentState = ({
  ovenID,
  userID,
}: {
  ovenID: string
  userID: number
}) => {
  const { invalidateOvenCookStatus } = useInvalidateOvens()

  const [currentCookStatus, setCurrentCookStatus] = useState<
    OvenCookStatus | undefined
  >()

  useOvenCookStatus({
    onSuccess: (cookStatus) => {
      setCurrentCookStatus(cookStatus)
    },
    ovenID,
    userID,
  })

  // Our ovens push realtime updates that we can subscribe to. Our oven cook
  // status call we make above is a snapshot, but this effect subscribes us
  // to realtime changes.
  useEffect(() => {
    const ovenChannel = pusher.subscribe(ovenID)

    ovenChannel.bind(
      'stateChanged',
      ({ currentState }: { currentState: string; previousState: string }) => {
        if (currentState === 'idle' || currentState === 'cooking_done') {
          invalidateOvenCookStatus(ovenID, userID)
        }
      }
    )

    return () => {
      pusher.unsubscribe(ovenID)
    }
  }, [invalidateOvenCookStatus, ovenID, userID])

  return currentCookStatus ? (
    <div className="text-sm">
      <OvenCurrentStateField label="Current Status">
        {currentCookStatus.state}
      </OvenCurrentStateField>
      {currentCookStatus.barcode && (
        <OvenCurrentStateField label="Barcode">
          {currentCookStatus.barcode}
        </OvenCurrentStateField>
      )}
      {currentCookStatus.estimated_start_time && (
        <OvenCurrentStateField label="Est. Start Time">
          {moment(currentCookStatus.estimated_start_time).format('h:mm A')}
        </OvenCurrentStateField>
      )}
      {currentCookStatus.estimated_end_time && (
        <OvenCurrentStateField label="Est. End Time">
          {moment(currentCookStatus.estimated_end_time).format('h:mm A')}
        </OvenCurrentStateField>
      )}
    </div>
  ) : null
}

const OvenCurrentStateField = ({
  children,
  label,
}: {
  children: ReactNode
  label: string
}) => {
  return (
    <p className="border-b border-grey-900 p-1">
      <strong>{label}: </strong>
      {children}
    </p>
  )
}
