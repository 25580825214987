import { clsx } from 'clsx'
import { flatMap } from 'lodash-es'
import {
  SortableContainer,
  SortableElement,
  SortEvent,
} from 'react-sortable-hoc'
import { TermMenuMeal } from '@tovala/browser-apis-combinedapi'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'
import { getTerm } from '../../actions/terms'
import { isTerm } from 'utils/terms'
import { updateMultipleMenuMeals } from '../../actions/meals'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import H1 from 'components/common/H1'

const reorderMeals = (
  list: TermMenuMeal[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const Meal = SortableElement<{ meal: TermMenuMeal; sortIndex: number }>(
  ({ meal, sortIndex }) => {
    const image = meal.images.find((img) => img.key === 'cell_tile')
    const tags = meal.tags.filter(
      (tag) =>
        tag.displayMode === 'menu_category' ||
        tag.displayMode === 'menu_subtitle'
    )

    return (
      <div className="relative w-full h-[400px] overflow-hidden border border-black-901 bg-white-900">
        {meal.id > 0 && (
          <div className="absolute right-0 top-0 bg-white-901 p-3 font-semibold">
            {sortIndex + 1}
          </div>
        )}
        {image && image.url && (
          <img className="max-h-[300px] w-full object-cover" src={image.url} />
        )}
        <div className="p-1">
          <div className="flex flex-wrap">
            {tags &&
              tags.map((tag) => (
                <div
                  key={tag.id}
                  className="mr-2 pl-1 pr-1 text-sm font-bold text-white-900"
                  style={{
                    backgroundColor: tag.color,
                  }}
                >
                  {tag.title}
                </div>
              ))}
          </div>
          <p className="font-bold">{meal.title}</p>
          <p className="text-sm">{meal.subtitle}</p>
        </div>
      </div>
    )
  }
)

const MealsList = SortableContainer<{ meals: TermMenuMeal[] }>(({ meals }) => {
  return (
    <div className="grid grid-cols-3 gap-2">
      {meals.map((meal, index) => {
        return (
          <Meal
            key={`${meal.id}${index}`}
            index={index}
            meal={meal}
            sortIndex={index}
          />
        )
      })}
    </div>
  )
})

interface Menus {
  [menuID: string]: TermMenuMeal[]
}

const TermMealDisplayOrders = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { termid } = useParams<{ termid: string }>()

  const selectedTerm = useAppSelector((state) => state.terms.selectedTerm)

  const [menus, setMenus] = useState<Menus>()
  const [selectedMenuID, setSelectedMenuID] = useState('')

  const onSortEnd = (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number
      newIndex: number
    },
    _event: SortEvent,
    { meals }: { meals: TermMenuMeal[] }
  ) => {
    const updatedMeals = [...meals].map((meal) => ({ ...meal }))

    const reorderedMeals = reorderMeals(updatedMeals, oldIndex, newIndex)

    const mealIDs = updatedMeals.map((meal) => meal.id)
    reorderedMeals.forEach((meal, index) => {
      const mealIndex = mealIDs.indexOf(meal.id)

      const updatedOrder = index + 1
      updatedMeals[mealIndex].mainDisplayOrder = updatedOrder
    })

    const updatedMenus = { ...menus }
    updatedMenus[selectedMenuID] = updatedMeals.sort(
      (a, b) => a.mainDisplayOrder - b.mainDisplayOrder
    )

    setMenus(updatedMenus)
  }

  const handleDisplayOrdersSubmit = (menus: Menus) => {
    for (const [menuID, meals] of Object.entries(menus)) {
      const updatedMeals = meals
        .map((meal) => {
          return {
            menuID,
            mealID: meal.id,
            mainDisplayOrder: meal.mainDisplayOrder,
            productionCode: meal.productionCode,
            expirationDate: meal.expirationDate,
          }
        })
        .filter((meal) => meal.mealID !== 0)

      dispatch(updateMultipleMenuMeals(menuID, updatedMeals))
    }
  }

  useEffect(() => {
    document.title = `Glaze | Meal Display Orders`
  }, [])

  useEffect(() => {
    if (termid) {
      dispatch(getTerm(termid)).then((response) => {
        if (response && response.payload) {
          const term = response.payload

          const menus = Object.fromEntries(
            flatMap(term.subTerms, (subTerm) => {
              return subTerm.menus
            }).map((menu) => {
              const meals = [...menu.meals].sort(
                (a, b) => a.mainDisplayOrder - b.mainDisplayOrder
              )
              return [menu.id, meals]
            })
          )

          const selectedMenuID = Object.keys(menus)[0]
          setMenus(menus)
          setSelectedMenuID(selectedMenuID)
        }
      })
    }
  }, [dispatch, termid])

  return (
    <div className="mx-auto w-full max-w-[1260px]">
      <div className="flex items-center justify-between">
        <H1>{termid && <span>Term #{termid}</span>} Meal Display Orders</H1>

        {getAdminScope(MEALS_WRITE) && menus && (
          <Button onClick={() => handleDisplayOrdersSubmit(menus)} size="large">
            Save All
          </Button>
        )}
      </div>

      <div className="my-4 flex space-x-8">
        {isTerm(selectedTerm) &&
          selectedTerm.subTerms &&
          selectedTerm.subTerms.map((subTerm) => {
            return subTerm.menus.map((menu) => {
              return (
                <button
                  key={`link-${menu.id}`}
                  className={clsx(
                    'text-sm uppercase tracking-widest hover:text-blue-901 hover:underline',
                    {
                      'font-bold underline': selectedMenuID === menu.id,
                    }
                  )}
                  onClick={() => {
                    setSelectedMenuID(menu.id)
                  }}
                >
                  {menu.name
                    ? menu.name
                    : `${subTerm.facilityNetwork}-${subTerm.shipPeriod}`}
                </button>
              )
            })
          })}
      </div>

      {menus && selectedMenuID && (
        <MealsList
          axis="xy"
          meals={menus[selectedMenuID]}
          onSortEnd={({ oldIndex, newIndex }, event) =>
            onSortEnd({ oldIndex, newIndex }, event, {
              meals: menus[selectedMenuID],
            })
          }
        />
      )}
    </div>
  )
}

export default TermMealDisplayOrders
