import {
  ErrorCodeMessageMapCombinedAPI,
  ListingAdmin,
  useTerm,
} from '@tovala/browser-apis-combinedapi'
import { APIErrorDisplay } from '@tovala/component-library'
import { flatMap } from 'lodash-es'
import { useParams } from 'react-router-dom'
import MenuProductTermListings from './MenuProductTermListings'
import { useMenuProductFull, useTermMenuListings } from 'hooks/menuProducts'
import Loader from 'components/common/Loader'

const LOAD_MENU_PRODUCT_LISTINGS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Menu Product and/or Listings',
    why: "We couldn't load the menu due to a technical issue on our end.",
  },
}

const MenuProductTermListingsPage = () => {
  const { productid: productID, termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const {
    hasLoadProductTermListingsError,
    isLoadingMenuProductDetailsJSON,
    isLoadingProductTermListings,
    listings = [],
    loadProductTermListingsError,
    menuProduct,
    menuProductDetails,
    menusWithListingIDs = [],
    term,
  } = useMenuProductTermListings({
    productID,
    termID,
  })

  if (hasLoadProductTermListingsError) {
    return (
      <div>
        <APIErrorDisplay
          display="page"
          error={loadProductTermListingsError}
          errorCodeMessageMap={LOAD_MENU_PRODUCT_LISTINGS_ERRORS}
        />
      </div>
    )
  }

  if (isLoadingProductTermListings) {
    return <Loader />
  }

  if (menuProduct && term) {
    return (
      <div className="font-sans-new">
        <MenuProductTermListings
          isLoadingMenuProductDetailsJSON={isLoadingMenuProductDetailsJSON}
          listings={listings}
          menuProduct={menuProduct}
          menuProductDetails={menuProductDetails}
          menusWithListingIDs={menusWithListingIDs}
          term={term}
        />
      </div>
    )
  }

  return null
}

export default MenuProductTermListingsPage

const useMenuProductTermListings = ({
  productID,
  termID,
}: {
  productID: string | undefined
  termID: number | undefined
}) => {
  const {
    data: term,
    error: loadTermError,
    isError: hasLoadTermError,
    isLoading: isLoadingTerm,
  } = useTerm({ termID })

  const {
    hasLoadMenuProductError,
    isLoadingMenuProduct,
    isLoadingMenuProductDetailsJSON,
    loadMenuProductError,
    menuProduct,
    menuProductDetails,
  } = useMenuProductFull({
    productID,
  })

  const menus = flatMap(
    term?.subTerms.map((subTerm) => subTerm.menus.map((menu) => menu)) ?? []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const {
    error: loadTermMenuListingsError,
    isError: hasLoadTermMenuListingsError,
    isLoading: isLoadingTermMenuListings,
    data: termMenuListings,
  } = useTermMenuListings({ termID: term?.id })

  const listings = flatMap(termMenuListings, (menuListings) => {
    return menuListings?.listings.filter((listing) => {
      return listing.productID === menuProduct?.id
    })
  }).filter((listing): listing is ListingAdmin => !!listing)

  const menusWithListingIDs = flatMap(
    termMenuListings,
    (menuListings, index) => {
      const menu = menus[index]
      const listing = menuListings?.listings.find(
        (listing) => listing.productID === menuProduct?.id
      )
      return {
        listingID: listing?.id,
        menu,
      }
    }
  )

  return {
    hasLoadProductTermListingsError:
      hasLoadTermError ||
      hasLoadMenuProductError ||
      hasLoadTermMenuListingsError,
    isLoadingProductTermListings:
      isLoadingTerm || isLoadingMenuProduct || isLoadingTermMenuListings,
    isLoadingMenuProductDetailsJSON,
    listings,
    loadProductTermListingsError:
      loadTermError ||
      loadMenuProductError ||
      loadTermMenuListingsError ||
      null,
    menuProduct,
    menuProductDetails,
    menus,
    menusWithListingIDs,
    term,
  }
}
