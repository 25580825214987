import { clsx } from 'clsx'
import { Formik, Form, useField } from 'formik'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  useSkipWeek,
  useSkippedWeeks,
  useUnskipWeek,
  useUserSubscriptionTypes,
  UserV1,
  useInvalidateTermStatuses,
  useInvalidateListings,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'

import {
  changeSubscriptionStatus,
  updatePlanPreferences,
  updateSubscription,
} from '../../actions/user'
import { errorHandler, successHandler } from 'actions/notifications'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { getAdminScope, USERS_WRITE } from '../../utils/getAdminScope'
import { getUserInfo } from '../../actions/auth'
import { isTerm } from 'utils/terms'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import H1 from 'components/common/H1'
import H2 from 'components/common/H2'
import H3 from 'components/common/H3'
import H4 from 'components/common/H4'
import Hr from 'components/common/Hr'
import MealPlan from './MealPlan'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import OvenOrderHistory from './OvenOrderHistory'
import Radio from 'components/common/Radio'

type SkippedStatus = 'scheduled' | 'skipped'

interface FormData {
  autofillBreakfastOK: string
  autofillSurchargeOK: string
  defaultShipPeriod: string
  doNotReplace: string
  hasBlackSheetTray: string
  premiumMealsOk: string
  single: string
  subscriptionTypeID: string
}

interface SubscriptionFormData {
  defaultShipPeriod: number
  subscriptionTypeID: string
}

interface PreferencesFormData {
  autofillBreakfastOK: boolean
  autofillSurchargeOK: boolean
  defaultShipPeriod: number
  doNotReplace: boolean
  hasBlackSheetTray: boolean | null
  premiumMealsOk: boolean
  single: boolean
}

const StyledRadio = (props: {
  id: string
  label: string
  name: string
  value: string
}) => {
  const [field] = useField({
    name: props.name,
    type: 'radio',
    value: props.value,
  })

  return <Radio {...field} {...props} />
}

const CancelPlanModal = ({
  changeSubscriptionStatus,
  onCloseModal,
  user,
}: {
  changeSubscriptionStatus: (userid: number, subscriptionStatus: string) => void
  onCloseModal: () => void
  user: UserV1
}): JSX.Element => {
  const handleCancelPlan = () => {
    changeSubscriptionStatus(user.id, 'cancel')
    onCloseModal()
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>Cancel Meal Plan</ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            {user.info.name && <p>{user.info.name}</p>}
            <p>{user.info.email}</p>
          </div>

          <p className="font-bold">
            Are you sure you want to cancel this user&apos;s meal plan?
          </p>

          <div className="flex space-x-2">
            <Button onClick={handleCancelPlan} size="large">
              Cancel Meal Plan
            </Button>
            <Button buttonStyle="cancel" onClick={onCloseModal} size="large">
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const PausePlanModal = ({
  changeSubscriptionStatus,
  onCloseModal,
  user,
}: {
  changeSubscriptionStatus: (userID: number, subscriptionStatus: string) => void
  onCloseModal: () => void
  user: UserV1
}): JSX.Element => {
  const handlePausePlan = () => {
    changeSubscriptionStatus(user.id, 'pause')
    onCloseModal()
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>Pause Meal Plan</ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            {user.info.name && <p>{user.info.name}</p>}
            <p>{user.info.email}</p>
          </div>

          <p className="font-bold">
            Are you sure you want to pause this user&apos;s meal plan?
          </p>

          <div className="flex space-x-2">
            <Button onClick={handlePausePlan} size="large">
              Pause Meal Plan
            </Button>
            <Button buttonStyle="cancel" onClick={onCloseModal} size="large">
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const UserSubscription = (): JSX.Element => {
  const { userid } = useParams<{ userid: string }>()

  const dispatch = useAppDispatch()

  const planPreferences = useAppSelector((state) => state.user.planPreferences)
  const term = useAppSelector((state) => state.terms.term)
  const user = useAppSelector((state) => state.auth.user)

  const userID = user?.id

  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()
  const { invalidateAllListingSelections } = useInvalidateListings()

  const { data: termStatuses } = useUserTermStatuses({ userID })

  const { data: getUserSubscriptionTypesResponse } = useUserSubscriptionTypes({
    userID,
  })
  const userSubscriptionTypes =
    getUserSubscriptionTypesResponse?.subscription_types ?? []

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isPausePlanModalOpen, setIsPausePlanModalOpen] = useState(false)

  const { data: skippedWeeks } = useSkippedWeeks({ userID })

  const { mutate: skipWeek } = useSkipWeek({
    onError: (err) => {
      errorHandler(dispatch, err)
    },
    onSuccess: (_res, { data }) => {
      successHandler(dispatch, `Success! Term #${data.termid} skipped.`)
    },
  })

  const { mutate: unskipWeek } = useUnskipWeek({
    onError: (err) => {
      errorHandler(dispatch, err)
    },
    onSuccess: (_res, { data }) => {
      successHandler(dispatch, `Success! Term #${data.termid} unskipped.`)
    },
  })

  const skipWeekToggle = (skippedStatus: SkippedStatus) => {
    if (isTerm(term) && term.id && userid) {
      const apiFn = skippedStatus === 'skipped' ? unskipWeek : skipWeek

      apiFn({ data: { termid: term.id }, userID: Number(userid) })
    }
  }

  const handleSubmit = (formData: FormData) => {
    if (!userid) {
      return
    }

    const subscription: SubscriptionFormData = {
      subscriptionTypeID: formData.subscriptionTypeID,
      defaultShipPeriod: Number.parseInt(formData.defaultShipPeriod, 10),
    }

    const updatedPreferences: PreferencesFormData = {
      defaultShipPeriod: Number.parseInt(formData.defaultShipPeriod, 10),
      single: formData.single === 'true',
      autofillBreakfastOK: formData.autofillBreakfastOK === 'true',
      autofillSurchargeOK: formData.autofillSurchargeOK === 'true',
      doNotReplace: formData.doNotReplace === 'true',
      premiumMealsOk: formData.premiumMealsOk === 'true',
      hasBlackSheetTray: formData.hasBlackSheetTray ? true : null,
    }

    dispatch(updatePlanPreferences(userid, updatedPreferences)).then(() => {
      dispatch(updateSubscription(userid, subscription)).then((response) => {
        if (response) {
          window.scrollTo(0, 0)
          dispatch(getUserInfo(userid))

          invalidateUserTermStatuses(Number.parseInt(userid, 10))
          invalidateAllListingSelections(Number.parseInt(userid, 10))
        }
      })
    })
  }

  const isBoolean = (value) => typeof value === 'boolean'

  useEffect(() => {
    document.title = `Glaze | User #${userid} - Meal Plan + Oven Order`
  }, [userid])

  // Double (false)/single (true) autoselect
  const autoselectOptions = [
    {
      label: 'No',
      value: 'true',
    },
    {
      label: 'Yes',
      value: 'false',
    },
  ]

  const autoselectBreakfastOptions = [
    {
      label: 'No',
      value: 'false',
    },
    {
      label: 'Yes',
      value: 'true',
    },
  ]

  const autoselectSurchargeOptions = [
    {
      label: 'No',
      value: 'false',
    },
    {
      label: 'Yes',
      value: 'true',
    },
  ]

  const substitutionOptions = [
    {
      label: "I'm fine with a meal substitution.",
      value: 'false',
      description:
        'For those with dietary restrictions, please note we cannot guarantee substitutions will be similar to your original selection.',
    },
    {
      label: "Don't substitute. I'd rather receive fewer meals.",
      value: 'true',
      description: "You will not be charged for any meals you don't receive.",
    },
  ]

  const premiumMealsOptions = [
    {
      label: 'Ask me to verify every time before adding',
      value: 'false',
    },
    {
      label: "Don't ask me to verify every time before adding",
      value: 'true',
    },
  ]

  const bstOptions = [
    {
      label: 'User has confirmed they have a black sheet tray',
      value: 'true',
    },
  ]

  const termStatus = termStatuses && termStatuses[0]
  const skippedStatus =
    isTerm(term) && term.id && skippedWeeks?.termids.includes(term.id)
      ? 'skipped'
      : 'scheduled'
  const skipWeekText = skippedStatus === 'skipped' ? 'Unskip Week' : 'Skip Week'
  const availableShipPeriods =
    user?.shippingAddresses[0]?.shipPeriodInfo
      .filter((s) => s.isAvailable)
      .sort((a, b) => a.shipPeriod - b.shipPeriod) ?? []
  const selectedSubTerm =
    termStatus &&
    termStatus.subTerms.find(
      (subTerm) => subTerm.subTermID === termStatus.selectedSubTermID
    )
  const defaultShipPeriodForAddress =
    user?.shippingAddresses[0]?.shipPeriodInfo?.find(
      (shipPeriod) => shipPeriod.isDefaultShipPeriodForAddress
    )?.shipPeriod

  const selectedSubscriptionMatchesDefault =
    user?.subscription.subscriptionType &&
    termStatus &&
    termStatus.subscriptionType &&
    termStatus.subscriptionType.maxSelections ===
      user.subscription.subscriptionType.maxSelections

  const booleanPreferences = [
    'single',
    'doNotReplace',
    'premiumMealsOk',
    'hasBlackSheetTray',
    'autofillBreakfastOK',
    'autofillSurchargeOK',
  ]

  const initialValues = {
    subscriptionTypeID: user?.subscription.subscriptionType?.id ?? '',
    defaultShipPeriod: '',
    single: '',
    doNotReplace: '',
    premiumMealsOk: '',
    hasBlackSheetTray: '',
    autofillBreakfastOK: '',
    autofillSurchargeOK: '',
  }

  if (planPreferences) {
    booleanPreferences.forEach((preference) => {
      initialValues[preference] = isBoolean(planPreferences[preference])
        ? planPreferences[preference].toString()
        : ''
    })

    if (planPreferences.defaultShipPeriod) {
      initialValues.defaultShipPeriod =
        planPreferences.defaultShipPeriod.toString()
    } else {
      initialValues.defaultShipPeriod = defaultShipPeriodForAddress
        ? defaultShipPeriodForAddress.toString()
        : ''
    }
  }

  return (
    <div>
      <div className="flex space-x-8">
        <div className="w-8/12">
          <H1>Meal Plan</H1>

          {user && (
            <MealPlan
              memberships={user.memberships}
              planPreferences={planPreferences}
              user={user}
            />
          )}

          {getAdminScope(USERS_WRITE) && (
            <Formik<FormData>
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {() => {
                return (
                  <Form className="mt-10 bg-grey-907 p-5">
                    <H4>Edit Default Meal Plan</H4>

                    <div className="space-y-2">
                      {userSubscriptionTypes &&
                        userSubscriptionTypes.map((subscription) => {
                          let shipping = ''
                          if (subscription.shippingCents > 0) {
                            shipping = ` + $${(
                              subscription.shippingCents / 100
                            ).toFixed(2)} shipping`
                          }

                          return (
                            <div key={subscription.id}>
                              <StyledRadio
                                id={subscription.id}
                                label={`${subscription.name} ($${(
                                  subscription.price_cents / 100
                                ).toFixed(2)}${shipping})`}
                                name="subscriptionTypeID"
                                value={subscription.id}
                              />
                            </div>
                          )
                        })}
                    </div>

                    <h5 className="my-4">Meal Plan Preferences</h5>
                    <H4>Default Delivery Day</H4>
                    <div>
                      {availableShipPeriods.length === 0 ? (
                        <span>No Available Delivery Days</span>
                      ) : (
                        <>
                          {availableShipPeriods &&
                            availableShipPeriods.map(
                              ({ deliveryDayOfTheWeek, shipPeriod }) => {
                                return (
                                  <div key={deliveryDayOfTheWeek}>
                                    <StyledRadio
                                      id={`shipPeriod-${shipPeriod.toString()}`}
                                      label={`${deliveryDayOfTheWeek}s`}
                                      name="defaultShipPeriod"
                                      value={shipPeriod.toString()}
                                    />
                                  </div>
                                )
                              }
                            )}
                        </>
                      )}
                    </div>

                    <Hr />

                    <H4>Autoselected Meals</H4>
                    <div className="mb-4 space-y-2">
                      <p>
                        For weeks you don&apos;t choose meals, skip, or have
                        your meal plan paused, we&apos;ll automatically send you
                        a chef-curated selection of meals and charge your card.
                      </p>
                      <p>When I don&apos;t select meals, send me:</p>
                    </div>
                    <div className="mb-4 flex space-x-4 pl-4">
                      <div className="w-8/12">
                        <p className="font-bold">Paired Meals</p>
                        <p>
                          We&apos;ll send you meals in pairs. (Ideal for two
                          people who eat together.)
                        </p>
                      </div>
                      <div className="flex w-4/12 space-x-4">
                        {autoselectOptions.map((option) => {
                          return (
                            <div
                              key={`single-${option.value}`}
                              className="grow"
                            >
                              <StyledRadio
                                id={`single-${option.value}`}
                                label={option.label}
                                name="single"
                                value={option.value}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="mb-4 flex space-x-4 pl-4">
                      <div className="w-8/12">
                        <p className="font-bold">Surcharged Meals</p>
                        <p>
                          If available, we&apos;ll include higher-end meals
                          featuring more expensive ingredients for a surcharge.
                        </p>
                      </div>
                      <div className="flex w-4/12 space-x-4">
                        {autoselectSurchargeOptions.map((option) => {
                          return (
                            <div
                              key={`autofillSurchargeOK-${option.value}`}
                              className="grow"
                            >
                              <StyledRadio
                                id={`autofillSurchargeOK-${option.value}`}
                                label={option.label}
                                name="autofillSurchargeOK"
                                value={option.value}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="mb-4 flex space-x-4 pl-4">
                      <div className="w-8/12">
                        <p className="font-bold">Breakfast Meals</p>
                        <p>
                          If available, we&apos;ll include breakfast meals in
                          our selection.
                        </p>
                      </div>
                      <div className="flex w-4/12 space-x-4">
                        {autoselectBreakfastOptions.map((option) => {
                          return (
                            <div
                              key={`autofillBreakfastOK-${option.value}`}
                              className="grow"
                            >
                              <StyledRadio
                                id={`autofillBreakfastOK-${option.value}`}
                                label={option.label}
                                name="autofillBreakfastOK"
                                value={option.value}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <Hr />

                    <H4>Meal Substitutions</H4>
                    <div className="mb-4 space-y-2">
                      <p>
                        In the very rare case a meal you select becomes
                        unavailable, please let us know what you&apos;d prefer.
                      </p>
                      <p>If a meal I order becomes unavailable:</p>
                    </div>
                    <div className="flex space-x-4 pl-4">
                      {substitutionOptions.map((option) => {
                        return (
                          <div
                            key={`doNotReplace-${option.value}`}
                            className="w-1/2"
                          >
                            <StyledRadio
                              id={`doNotReplace-${option.value}`}
                              label={option.label}
                              name="doNotReplace"
                              value={option.value}
                            />
                            <p className="pl-8 text-sm">{option.description}</p>
                          </div>
                        )
                      })}
                    </div>

                    <Hr />

                    <H4>Surcharged Meals</H4>
                    <p className="mb-4">
                      Do you want us to verify your meal selection every time
                      you add a surcharged meal to your delivery?
                    </p>
                    <div className="flex pl-4">
                      {premiumMealsOptions.map((option) => {
                        return (
                          <div
                            key={`premiumMealsOk-${option.value}`}
                            className="w-1/2"
                          >
                            <StyledRadio
                              id={`premiumMealsOk-${option.value}`}
                              label={option.label}
                              name="premiumMealsOk"
                              value={option.value}
                            />
                          </div>
                        )
                      })}
                    </div>

                    <Hr />

                    <H4>User Has Black Sheet Tray</H4>
                    <p className="mb-4">
                      Some meals require the black sheet tray to cook the meal.
                      Does the user still have the black sheet tray that came
                      with their oven?
                    </p>
                    <div>
                      {bstOptions.map((option) => {
                        return (
                          <StyledRadio
                            key={`hasBlackSheetTray-${option.value}`}
                            id={`hasBlackSheetTray-${option.value}`}
                            label={option.label}
                            name="hasBlackSheetTray"
                            value={option.value}
                          />
                        )
                      })}
                    </div>
                    {user && (
                      <div className="mt-10">
                        <Button size="large" type="submit">
                          {user.subscription.status === 'active'
                            ? 'Update Meal Plan'
                            : 'Save Meal Plan'}
                        </Button>
                      </div>
                    )}
                  </Form>
                )
              }}
            </Formik>
          )}
        </div>

        <div className="w-4/12">
          {getAdminScope(USERS_WRITE) &&
            user?.subscription.status === 'active' && (
              <div>
                <H3>Skip</H3>
                <div className="mb-4">
                  {isTerm(term) && (
                    <p className="font-bold">
                      Delivery for week of{' '}
                      {formatDate(new Date(term.start), {
                        format: DATE_FORMATS.MONTH_ABBR_DAY,
                      })}
                    </p>
                  )}
                  <p
                    className={clsx('font-semibold uppercase', {
                      'text-green-905': skippedStatus === 'scheduled',
                      'text-red-901': skippedStatus === 'skipped',
                    })}
                  >
                    {skippedStatus}
                  </p>
                </div>

                {skippedStatus === 'scheduled' && (
                  <div className="space-y-2">
                    <div>
                      <p className="font-bold">Meals this week</p>
                      <div className="pl-3">
                        {user.subscription.subscriptionType && (
                          <span
                            className={clsx({
                              'line-through':
                                !selectedSubscriptionMatchesDefault,
                            })}
                          >
                            {user.subscription.subscriptionType?.maxSelections}{' '}
                            Meal Plan
                          </span>
                        )}
                        {termStatus && !selectedSubscriptionMatchesDefault && (
                          <span>
                            &nbsp;&nbsp;&nbsp;
                            {termStatus.subscriptionType?.maxSelections} Meals
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="font-bold">Delivery Day</p>
                      <p className="pl-3">
                        {selectedSubTerm &&
                          formatDate(new Date(selectedSubTerm.deliveryDate), {
                            format: DATE_FORMATS.DOW_MONTH_ABBR_DAY,
                          })}
                      </p>
                    </div>
                  </div>
                )}

                <p className="my-4">
                  Delivery will automatically re-activate the following week if
                  skipped.
                </p>

                <div className="flex space-x-4">
                  <Button
                    onClick={() => skipWeekToggle(skippedStatus)}
                    size="large"
                  >
                    {skipWeekText}
                  </Button>

                  {getAdminScope(USERS_WRITE) &&
                    skippedStatus === 'scheduled' &&
                    isTerm(term) && (
                      <Link to={`/user/${userid}/meals/${term.id}`}>
                        <Button buttonStyle="stroke" size="large">
                          Edit
                        </Button>
                      </Link>
                    )}
                </div>

                <Hr />

                {user.subscription.subscriptionType && (
                  <div>
                    <H3>Pause</H3>
                    {user.subscription.isCommitment ? (
                      <div>
                        Meal Plan cannot be paused while customer is in a
                        commitment.
                      </div>
                    ) : (
                      <div className="space-y-2">
                        {isTerm(term) && (
                          <p className="font-bold">
                            Delivery starting week of{' '}
                            {formatDate(new Date(term.start), {
                              format: DATE_FORMATS.MONTH_ABBR_DAY,
                            })}
                          </p>
                        )}

                        <p>This will pause delivery until reactivated.</p>
                        {isPausePlanModalOpen && (
                          <PausePlanModal
                            changeSubscriptionStatus={(...args) => {
                              return dispatch(changeSubscriptionStatus(...args))
                            }}
                            onCloseModal={() => {
                              setIsPausePlanModalOpen(false)
                            }}
                            user={user}
                          />
                        )}
                        <Button
                          buttonStyle="grey"
                          onClick={() => {
                            setIsPausePlanModalOpen(true)
                          }}
                          size="large"
                        >
                          Pause Meal Plan
                        </Button>
                      </div>
                    )}

                    <Hr />

                    <H3>Cancel</H3>
                    <div className="space-y-2">
                      {isTerm(term) && (
                        <p className="font-bold">
                          Delivery starting week of{' '}
                          {formatDate(new Date(term.start), {
                            format: DATE_FORMATS.MONTH_ABBR_DAY,
                          })}
                        </p>
                      )}

                      <p>
                        This will cancel delivery until reactivated and remove
                        plan type.
                      </p>
                      {isCancelModalOpen && (
                        <CancelPlanModal
                          changeSubscriptionStatus={(...args) => {
                            return dispatch(changeSubscriptionStatus(...args))
                          }}
                          onCloseModal={() => {
                            setIsCancelModalOpen(false)
                          }}
                          user={user}
                        />
                      )}
                      <Button
                        buttonStyle="grey"
                        onClick={() => {
                          setIsCancelModalOpen(true)
                        }}
                        size="large"
                      >
                        Cancel Meal Plan
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>

      <Hr />
      <H2>Tovala.com Oven Order History</H2>
      {user && <OvenOrderHistory isAccountOverview={false} user={user} />}
    </div>
  )
}

export default UserSubscription
