import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  PublicCalendarTerms,
  Term,
  TermMenu,
  TermMenuUpdated,
} from '@tovala/browser-apis-combinedapi'

import { API_URL, API_URL_V1, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'
import {
  GET_ALL_TERMS,
  GET_MENU,
  GET_NEXT_TERM,
  GET_PUBLIC_CALENDAR,
  GET_TERM,
  UPDATE_SIDE_SWAP,
} from './types'

// ================================
// Authentication actions
// ================================

// Get all terms (SYSADMIN)
// GET https://api.tovala.com/v0/terms
export function getAllTerms(): AppThunk<
  Promise<PayloadAction<unknown, typeof GET_ALL_TERMS> | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL}/terms`, getHeaders())
      .then((response) => {
        return dispatch({
          type: GET_ALL_TERMS,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v1/terms/:termID
export function getTerm(
  termid: string
): AppThunk<Promise<PayloadAction<Term, typeof GET_TERM> | void>> {
  return function (dispatch) {
    return axios
      .get<Term>(`${API_URL_V1}/terms/${termid}`, getHeaders())
      .then((response) => {
        const term = Object.assign({}, response.data)
        const sortedSubTerms = [...term.subTerms].sort(
          (a, b) =>
            a.facilityNetwork.localeCompare(b.facilityNetwork) ||
            a.shipPeriod - b.shipPeriod
        )
        term.subTerms = sortedSubTerms

        return dispatch({
          type: GET_TERM,
          payload: term,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v1/terms/query/next
export function getNextTerm(): AppThunk<
  Promise<PayloadAction<unknown, typeof GET_NEXT_TERM> | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL_V1}/terms/query/next`, getHeaders())
      .then((response) => {
        return dispatch({
          type: GET_NEXT_TERM,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v1/terms/query/calendarView
export function getPublicCalendar(): AppThunk<
  Promise<PayloadAction<PublicCalendarTerms, typeof GET_PUBLIC_CALENDAR> | void>
> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .get<PublicCalendarTerms>(
        `${API_URL_V1}/terms/query/calendarView`,
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        return dispatch({
          type: GET_PUBLIC_CALENDAR,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT
// https://api.tovala.com/v0/terms/:termID/readyForView
export function readyForView(termId: number): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())

    axios
      .put(`${API_URL}/terms/${termId}/readyForView`, {}, getHeaders())
      .then((response) => {
        if (response.status === 200) {
          dispatch(hideLoading())

          return dispatch(getPublicCalendar())
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// GET https://api.tovala.com/v1/menus/:menuID
export function getMenu(
  menuID: string
): AppThunk<Promise<PayloadAction<TermMenu, typeof GET_MENU> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .get<TermMenu>(`${API_URL_V1}/menus/${menuID}`, getHeaders())
      .then((response) => {
        dispatch(hideLoading())

        return dispatch({
          type: GET_MENU,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/menus/:menuID/sideSwapCombos
export function addSideSwapsForMenu(
  menuID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<true, typeof UPDATE_SIDE_SWAP> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .put(
        `${API_URL_V1}/menus/${menuID}/sideSwapCombos`,
        payload,
        getHeaders()
      )
      .then(() => {
        dispatch(hideLoading())

        successHandler(dispatch, `Success! Customize It meals saved.`)
        return dispatch({
          type: UPDATE_SIDE_SWAP,
          payload: true as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE https://api.tovala.com/v1/menus/:menuID/sideSwapCombos
export function deleteSideSwapsForMenu(
  menuID: string
): AppThunk<Promise<PayloadAction<true, typeof UPDATE_SIDE_SWAP> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .delete(`${API_URL_V1}/menus/${menuID}/sideSwapCombos`, getHeaders())
      .then(() => {
        dispatch(hideLoading())

        successHandler(dispatch, `Success! Customize It pairs deleted.`)

        return dispatch({
          type: UPDATE_SIDE_SWAP,
          payload: true as const,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT https://api.tovala.com/v1/menus/:menuID
export function updateMenuInfo(
  menuID: string,
  payload: unknown
): AppThunk<Promise<PayloadAction<TermMenuUpdated, typeof GET_MENU> | void>> {
  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .put<TermMenuUpdated>(
        `${API_URL_V1}/menus/${menuID}`,
        payload,
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        successHandler(dispatch, `Success! menu info updated.`)

        return dispatch({
          type: GET_MENU,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}
