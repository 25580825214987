import { MealAdmin, Term } from '@tovala/browser-apis-combinedapi'
import { useEffect } from 'react'

import { getAdminScope, MEALS_WRITE } from '../../utils/getAdminScope'
import { getAllTerms, getTerm } from '../../actions/terms'

import { useAppDispatch, useAppSelector } from 'hooks'
import Button from 'components/common/Button'
import MealForm from './MealForm'
import { isTerm } from 'utils/terms'

const UpdateMeal = ({
  meal,
  mealid,
  onDuplicateMeal,
  toggleRemoveFromTermModal,
}: {
  meal: MealAdmin
  mealid: string
  onDuplicateMeal: () => void
  toggleRemoveFromTermModal: () => void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const allMealTags = useAppSelector((state) => state.mealTags.allMealTags)
  const allTerms = useAppSelector((state) => state.terms.allTerms)
  const publicCalendar = useAppSelector((state) => state.terms.publicCalendar)
  const selectedTerm = useAppSelector((state) => state.terms.selectedTerm)

  let shouldUsePublicCalendar = true
  let publicCalendarTerms: Term[] = []
  if (publicCalendar) {
    const past = publicCalendar.past.terms.map((term) => term)
    const present = publicCalendar.present.term
    const future = publicCalendar.future.terms.map((term) => term)

    publicCalendarTerms = [...past, present, ...future]
    const publicCalendarTermIDs = publicCalendarTerms.map((term) => term.id)

    shouldUsePublicCalendar =
      !meal.termid || publicCalendarTermIDs.includes(meal.termid)
  }

  useEffect(() => {
    // Getting all the terms is a more expensive call so we only fetch those
    // terms if we need to (i.e. the current meal isn't in the public calendar).
    if (publicCalendar && !shouldUsePublicCalendar && allTerms.length === 0) {
      dispatch(getAllTerms())
    }
  }, [dispatch, allTerms, publicCalendar, shouldUsePublicCalendar])

  useEffect(() => {
    if (meal.termid !== null && meal.termid > 0) {
      const termID = `${meal.termid}`
      dispatch(getTerm(termID))
    }
  }, [dispatch, meal.termid])

  return (
    <MealForm
      allMealTags={allMealTags}
      allTerms={allTerms}
      calendarTerms={allTerms.length ? allTerms : publicCalendarTerms}
      form="updateMeal"
      meal={meal}
      mealid={mealid}
      readOnly={!getAdminScope(MEALS_WRITE)}
      selectedTerm={isTerm(selectedTerm) ? selectedTerm : undefined}
    >
      {getAdminScope(MEALS_WRITE) && (
        <div className="space-y-2">
          <div className="h-10">
            <Button onClick={onDuplicateMeal} size="fluid">
              Duplicate Meal
            </Button>
          </div>
          {meal.termid && meal.termid !== 0 && (
            <div className="h-10">
              <Button
                buttonStyle="grey"
                onClick={toggleRemoveFromTermModal}
                size="fluid"
              >
                Remove from Term
              </Button>
            </div>
          )}
        </div>
      )}
    </MealForm>
  )
}

export default UpdateMeal
